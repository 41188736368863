import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Api_Service/api.service';
import { CommonService } from 'src/app/Common_Service/common.service';
import { endpoint } from 'src/app/Constants/endpoints';
import { regex } from 'src/app/Constants/regex';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  showPassword: boolean;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
  constructor(private router: Router,
    private commonService: CommonService,
    private apiservice: ApiService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group({
      "oldPassword": ['', Validators.required],
      "newPassword": ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern(regex.password)])],
      "confirmPassword": ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(20)])]
    })

  }
  //  PassWord show and hide **//
  showHidePassword(val) {
    if (val == 'old') {
      this.showPassword = !this.showPassword;
    } else if (val == 'new') {
      this.showNewPassword = !this.showNewPassword;
    } else {
      this.showConfirmPassword = !this.showConfirmPassword;
    }


  }
  // Change password Api **//
  changepassword() {

    this.commonService.presentSpinner();
    let obje = {
      "oldPassword": this.changePasswordForm.value.oldPassword,
      "newPassword": this.changePasswordForm.value.newPassword,
      "confirmPassword": this.changePasswordForm.value.confirmPassword
    }
    this.apiservice.putApi(endpoint.changePassword, obje, 1).subscribe(res => {
      console.log('res', res)
      if (res.body.code || res.responseCode == 200) {
        this.commonService.dismissSpinner();
        this.commonService.successToaster(res.body.message)
        this.commonService.dismissSpinner();
        this.router.navigate(['/dashboard/my-course'])

      }
    }, (error) => {
      this.commonService.dismissSpinner();
      if (error.error.message) {
        this.commonService.errorToast(error.error.message)

      } else {
        this.commonService.dismissSpinner();
        this.commonService.errorToast(error.statusText)

      }
    })

  }
}
