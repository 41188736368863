import { Component } from '@angular/core';
import { GenericService } from './Api_Service/generic.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'iut';
  constructor(
    private apiService: GenericService,) {

  }
}
