import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Api_Service/api.service';
import { GenericService } from 'src/app/Api_Service/generic.service';
import { CommonService } from 'src/app/Common_Service/common.service';
import { endpoint } from 'src/app/Constants/endpoints';
import { router } from 'src/app/Constants/Router';

@Component({
  selector: 'app-my-course',
  templateUrl: './my-course.component.html',
  styleUrls: ['./my-course.component.css']
})
export class MyCourseComponent implements OnInit {
  mycourseDetails: any;
  contentLoaded: boolean = false;
  constructor(private ApiService: ApiService,
    private commonService: CommonService,
    private generic: GenericService,
    private router: Router) { }
  ngOnInit() {
    // if(localStorage.getItem('key')){
    //   localStorage.removeItem('key')
    //   location.reload();
    // }
    this.getMyCourse()

  }

  // Get My Course Api **//
  getMyCourse() {
    this.contentLoaded = true;
    // this.commonService.presentSpinner();
    this.ApiService.getApi(endpoint.myCourse, 1).subscribe(res => {

      console.log('error401', res)
      if (res.body.code || res.responseCode == 200) {
        this.contentLoaded = false;

        // this.commonService.successToaster(res.body.message)
        this.commonService.dismissSpinner();
        this.mycourseDetails = res.body.courseList
      }
    }, (error) => {
      console.log('error401',)
      if (error.error.code == 401) {
        this.commonService.logOut();
        this.commonService.errorToast(error.error.message)

      }
    })
  }


  sendId(data) {

    var courseid = data._id;
    var assignCourseId = data.assignedUser[0].assignCourseId;
    // this.router.navigate([router.chapter, { id: _id }])
    console.log(data)
    this.router.navigate([router.chapter], { queryParams: { courseid: courseid, assigncourseid: assignCourseId } })
  }
}


