import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Api_Service/api.service';
import { CommonService } from 'src/app/Common_Service/common.service';
import { endpoint } from 'src/app/Constants/endpoints';
import { regex } from 'src/app/Constants/regex';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  showPassword: boolean;
  showConfirmPassword: boolean;
  url: string;
  linkeUrl: string;
  token: any;
  constructor(private router: Router,
    private commonService: CommonService,
    private fb: FormBuilder,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
  ) {

    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params.token
    });
  }

  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group({
      "newPassword": ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern(regex.password)])],
      "confirmPassword": ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(20)])]
    })
    this.url = localStorage.getItem('url');
    console.log('url')

    // this.passwordLink()

  }

  //  Reset-Password Api
  resetPassword() {

    let obje = {
      "password": this.resetPasswordForm.value.newPassword,
      "confirmPassword": this.resetPasswordForm.value.confirmPassword,
      "token": this.token
    }
    this.apiService.postApi(endpoint.resetPassword, obje, 0).subscribe(res => {
      console.log('res', res)
      if (res.body.code || res.responseCode == 200) {
        this.commonService.successToaster(res.body.message);
        this.commonService.dismissSpinner();
        this.router.navigate(['/dashboard/login'])

      }
    }, (error) => {
      console.log('errors', error)
      if (error.error.message) {
        this.commonService.dismissSpinner();
        this.commonService.errorToast(error.error.message)

      } else {
        this.commonService.dismissSpinner();
        this.commonService.errorToast(error.statusText)
      }
    })
  }

  // passwordLink() {
  //   let link = this.url.split('reset/')
  //   if (link) {
  //     this.linkeUrl = link[1]
  //     console.log('link', this.linkeUrl)
  //   }

  // }

}
