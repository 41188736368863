<div id="wrapper">
    <div class="content">
        <div class="assessment-form">
            <div class="container">
                <div class="question-form">
                    <div class="chapter-bar">
                        <a href="javascript:void(0)" class="back-btn" (click)="goback()">Back</a>
                        <h2 class="chapter-title"> {{chapterName}} </h2>
                    </div>

                    <form id="msform">
                        <!-- progressbar -->
                        <div class="progress">
                            <div id="progress" class="progress-bar progress-bar-striped progress-bar-animated"
                                role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <!-- filteredQuestions.length == {{filteredQuestions.length}} -->
                        <fieldset *ngIf="filteredQuestions.length == 0">
                            <!-- {{list|json}} -->
                            <div class="form-card">
                                <div class="row">
                                    <div class="steps">
                                        <h4>
                                            <ngx-skeleton-loader
                                                [theme]="{width: '200px','border-radius': '0',height: '15px','margin-bottom': '10px'}">
                                            </ngx-skeleton-loader>
                                        </h4>
                                    </div>
                                    <div class="form-question step-1 ">
                                        <h3>
                                            <ngx-skeleton-loader
                                                [theme]="{width: '200px','border-radius': '0',height: '15px','margin-bottom': '10px'}">
                                            </ngx-skeleton-loader>
                                        </h3>

                                        <label class="radio-box ">
                                            <div>
                                                <ngx-skeleton-loader
                                                    [theme]="{width: '200px','border-radius': '0',height: '15px','margin-bottom': '10px'}">
                                                </ngx-skeleton-loader>
                                                <span class="span-box">
                                                    <ngx-skeleton-loader
                                                        [theme]="{width: '200px','border-radius': '0',height: '15px','margin-bottom': '10px'}">
                                                    </ngx-skeleton-loader>
                                                </span>
                                            </div>
                                            <label>

                                                <span class="span-box">
                                                    <ngx-skeleton-loader
                                                        [theme]="{width: '200px','border-radius': '0',height: '15px','margin-bottom': '10px'}">
                                                    </ngx-skeleton-loader>
                                                </span>

                                            </label>
                                        </label>
                                    </div>

                                </div>
                            </div>

                        </fieldset>
                        <!-- {{filteredQuestions|json}} -->
                        <fieldset *ngFor="let list of filteredQuestions">
                            <!-- {{list|json}} -->
                            <div class="form-card">
                                <div class="row">
                                    <div class="steps">
                                        <h4>Question {{pager.index +1}} / {{list.totallength}}</h4>
                                    </div>
                                    <!-- list =={{list.givenAnswer}} -->
                                    <div class="form-question step-1 " [ngClass]="{'review-question': isSubmitted}">
                                        <h3>{{list.question}}</h3>

                                        <label class="radio-box " *ngFor="let ans of list.options; let i = index">
                                            <div *ngIf="!isSubmitted">
                                                <input [(ngModel)]="list.givenAnswer" name="{{list._id}}" type="radio"
                                                    value="{{ans.optionNumber}}"
                                                    (click)="SelectAnswer(ans.optionNumber,pager.index)">
                                                <span class="span-box">{{ans.optionValue}}</span>
                                            </div>
                                            <label *ngIf="isSubmitted" class="incorrect"
                                                [ngClass]="{'correct': list.correctAnswer == list.givenAnswer && ans.optionNumber == list.correctAnswer,'correct': list.correctAnswer != list.givenAnswer && ans.optionNumber == list.correctAnswer,'incorrect':list.correctAnswer != list.givenAnswer && ans.optionNumber == list.givenAnswer, 'correct': list.correctAnswer == ans.optionNumber}">
                                                <input [(ngModel)]="list.givenAnswer" name="{{list._id}}" type="radio"
                                                    value="{{ans.optionNumber}}" [attr.disabled]="isSubmitted">
                                                <span class="span-box">{{ans.optionValue}}</span>
                                                <!-- <span
                                                    *ngIf="list.correctAnswer == list.givenAnswer && ans.optionNumber == list.correctAnswer"
                                                    class="correct">correct1</span>
                                                <span
                                                    *ngIf="list.correctAnswer != list.givenAnswer && ans.optionNumber == list.correctAnswer"
                                                    class="correct">correct2</span>

                                                <span
                                                    *ngIf="list.correctAnswer != list.givenAnswer && ans.optionNumber == list.givenAnswer"
                                                    class="Incorrect">Incorrect</span> -->
                                            </label>
                                        </label>
                                    </div>

                                </div>
                            </div>
                            <input type="button" name="previous" class="previous action-button-previous"
                                value="Previous" (click)="goTo(pager.index - 1,list,'previous');"
                                *ngIf="pager.index > 0 && isSubmitted == false" />
                            <input type="button" name="next" class="next action-button" [disabled]="!list.givenAnswer"
                                value="Next" (click)="goTo(pager.index + 1, list,'next');"
                                *ngIf="pager.index != (list.totallength-1) && isSubmitted == false" />
                            <input type="button" name="next" class="next action-button" [disabled]="!list.givenAnswer"
                                value="Submit" (click)="goTo('',list,'submit');"
                                *ngIf="pager.index == (list.totallength-1) && isSubmitted == false" />
                            <input type="button" name="next" class="previous  action-button " value="Previous"
                                *ngIf="isSubmitted == true && pager.index > 0" (click)="Review(pager.index - 1);" />
                            <input type="button" name="next" class="next action-button" value="Next"
                                (click)="Review(pager.index + 1);"
                                *ngIf="pager.index != (list.totallength-1) && isSubmitted == true" />
                        </fieldset>

                    </form>
                    <div class="popup" data-popup="popup-1" style="display: block;" *ngIf="ShowScoreModal">
                        <div class="popup-inner score-inner">
                            <h3>Your Score</h3>
                            <p>{{CorrectAnwser?CorrectAnwser:'0'}} Answer(s) are correct out of {{totalQuestion}} </p>
                            <a class="btn" href="javascript:void(0)" (click)="HideScoremodal()">Done</a>
                            <a class="popup-close" data-popup-close="popup-1" href="javascript:void(0)"
                                (click)="HideScoremodal()"><img src="assets/images/popup-close.png"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>