import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../Api_Service/api.service';
import { endpoint } from '../Constants/endpoints';
import { router } from '../Constants/Router';
import { Subject } from 'rxjs'
import { GenericService } from '../Api_Service/generic.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private toastNotification: ToastrService, private spinner: NgxSpinnerService, private router: Router, private ApiService: ApiService,
    private http: HttpClient,
    public generic: GenericService
    ,) { }

  //  Success toastee notification  **  //
  successToaster(msg, maxShown: any = '1') {
    this.toastNotification.success(msg);
  }

  //  Error toaster notification  **  //
  errorToast(msg) {

    this.toastNotification.error(msg)
  }

  handleError(error: any) {
    console.log('error ====>>>', error);
    if (error.error.code == 422) {
      // this.dismissSpinner();
      this.toastNotification.error(error.error.message)
    } else if (error.error.code == 401) {
      // this.dismissSpinner();
      this.toastNotification.error(error.error.message)
    } else if (error.error.code == 500) {
      // this.dismissSpinner();
      this.toastNotification.error(error.error.message)
    } else if (error.error.code == 401) {
      // this.router.navigate(['/dashboard/login']);
      console.log('error ====>>>', error);

    }
    else {
      this.toastNotification.error("sadsad")

    }

  }

  // Page loading spinner **  //
  presentSpinner() {
    this.spinner.show();
    setTimeout(() => {
      this.dismissSpinner();
    }, 5000);
  }

  // Page loading dismisspinner **  //

  dismissSpinner() {
    this.spinner.hide();
  }
  logOut() {

    this.ApiService.postApi(endpoint.logOut, "", 1).subscribe(res => {
      if (res.body.code || res.responseCode == 200) {
        // this.successToaster('LogOut successfully')
        localStorage.clear();
        this.generic.isLogin = false;
        this.router.navigate([router.dashboard])
      }
    }, (error) => {
      if (error.error.code == 401) {
        this.generic.isLogin = false;
        localStorage.clear();
        this.router.navigate([router.dashboard])
        // this.errorToast(error.error.message)
      } else {
        this.generic.isLogin = false;
        localStorage.clear();
        this.router.navigate([router.dashboard])
        // this.errorToast(error.message)
      }
    })

  }

  // localStorage.removeItem('token')
  // this.router.navigate(['/dashboard/login'])
  profile = `${localStorage.getItem('data')}`

  // getUserDetail(){
  //   let profile=JSON.parse(localStorage.getItem('data'))
  //   // if(profile !=null){
  //   //   return this.http.get(profile)
  //   // }
  // }

  userInfo = new Subject<any>()
}

