<div id="wrapper">
  <div class="inner-banner" style="background-image: url(assets/images/Image_61.png);">
    <div class="container">
      <!-- <h1 class="page-title">Access Institute of Ultrasound Training Courses</h1> -->
    </div>
  </div>
  <div class="content">
    <div class="container">
      <div class="form-login">
        <h2 class="form-title" (buttonClicked)='showNextComponent($event)'>Login</h2>
        <form [formGroup]="loginForm">
          <div class="mb-3">
            <div class="input input_field input_field_full">
              <div class="input-icon"><img src="assets/images/email-icon.png"></div>
              <input type="email" id="email" (keydown.space)="$event.preventDefault()" formControlName="email"
                placeholder="Email">

            </div>
            <div
              *ngIf="loginForm.get('email').errors && (loginForm.get('email').touched || loginForm.get('email').dirty)">
              <div *ngIf="loginForm.controls['email'].hasError('required')"><span class="fonts error-message">Mail
                  id is required.</span></div>
              <div class="fonts error-message"
                *ngIf="loginForm.get('email').hasError('maxlength') && loginForm.get('email').dirty">
                Maxlength of characters should be 64.
              </div>
              <div *ngIf="loginForm.controls['email'].hasError('pattern')"><span class="fonts error-message">Please
                  enter valid
                  mail id.</span>
              </div>
              <!-- hghsgdhshd -->
            </div>
          </div>
          <div class="mb-3">
            <div class="input  input_field">
              <div class="input-icon"><img src="assets/images/password-icon.png"></div>
              <input [type]="showPassword ? 'text' : 'password'" (keydown.space)="$event.preventDefault()"
                formControlName="password" class="password" name="password" placeholder="Password" required>
              <div class="input-group-append password-show"><span class="ca-eye" (click)="showHidePassword()"></span>
              </div>
              <!-- <span class="bg-white border-0 input-group-text">
                <img (click)="showHidePassword()"
                [src]="showPassword ? 'assets/icon/eye-view.svg' :'assets/icon/eye.svg'"></span> -->
            </div>

            <!-- Login validation start -->
            <!-- <div class="fonts" *ngIf="loginForm.get('password').hasError('required') && loginForm.get('password').touched">
            <span class="fonts">Password is required.</span>
          </div>
          <div class="fonts error-message" *ngIf="loginForm.get('password').hasError('maxlength') && loginForm.get('password').dirty"
            >
            Maxlength of characters should be 20.
          </div>
          <div *ngIf="loginForm.get('password').hasError('pattern') && loginForm.get('password').dirty">
            <span class="fonts error-message error-message"> Password must contain characters, digits and one special character(Min
              character-8).</span>
          </div> -->
          </div>
          <div class="remember-bar">
            <!-- <label class="remember" >
            <input name="rememberme" type="checkbox"
                formControlName="rememberMe" id="rememberme"  value="forever">
                <span>Remember</span>
              </label> -->
            <a class="forgot-password" [routerLink]="['/dashboard/forgot-Password']">Forgot password?</a>
          </div>
          <div class="input button-submit"><input name="button" type="submit" (click)="login()" value="Login"
              id="submit"></div>
        </form>
      </div>
    </div>
  </div>
</div>