<div id="wrapper">
    <div class="content">
        <div class="inner-banner" style="background-image: url(../../assets/images/Image_61.png);">
            <div class="container">
                <h1 class="page-title">Terms & Condition</h1>
            </div>
        </div>

        <div class="profile-right profile_content content-tab">
            <div class="container">
                <p>This Website is created and operated by <strong>Institute of Ultrasound Training</strong>
                    having its
                    registered office at <strong>“511-12, Westend Mall, Janakpuri, New Delhi-110058
                        India”</strong>
                    represented by its Directors, hereinafter referred to as the <strong>“Company”</strong>
                    (where such
                    expression shall, unless repugnant to the context thereof, be deemed to include its
                    respective
                    legal heirs, representatives, administrators, permitted successors and assigns).</p>
                <p>
                    This legal agreement is an electronic record in terms of Indian Information Technology
                    Act,
                    2000 and rules there under as applicable and the amended provisions about electronic
                    records in various statutes as amended by the Indian Information Technology Act, 2000.
                    This electronic record is generated by a computer system and does not require any
                    physical
                    or digital signatures.</p>
                <p>
                    This legal document is published in accordance with the provisions of Rule 3 (1) of the
                    Indian Information
                    Technology (Intermediaries guidelines) Rules, 2011 and Rule 4 of the Information
                    Technology (Reasonable security
                    practices and procedures and sensitive personal data or information) Rules, 2011 of
                    Information Technology Act, 2000
                    amended through Information Technology Amendment Act, 2008 that require publishing the
                    Terms of Services and
                    practices for access or usage of <strong>“drrandhawainstitute.com”
                        (“Website/platform”)</strong>

                </p>
                <p>The creator of these Terms of Service ensures your steady commitment to the usage of your
                    platform and the services
                    provided by the company on the Website <strong> “drrandhawainstitute.com”
                    </strong>(herein after referred to as the
                    <strong>“Platform”</strong>).</p>
                <p>For the purpose of these Terms of Use <strong>(“Terms”)</strong>, wherever the context so
                    requires, “We”, “Our”, and
                    “Us” shall mean
                    and refer to the Platform and/or the Company, as the context so requires. “You”, “Your”,
                    “Yourself”, “User” shall
                    mean and refer to natural and legal individuals who shall be Users of this Platform
                    provided by the company and who
                    is competent to enter into binding contracts, as per Indian laws. “Third Parties” refer
                    to any Website, Platform or
                    individual apart from the Users and the creator of this platform. “Platform” refers to
                    the Website created by the
                    Company.</p>
                <h3><strong>GENERAL TERMS</strong></h3>

                <ul>
                    <li>The headings of each section in these Terms or Privacy Policy are only for the
                        purpose of organizing the various
                        provisions under these Terms and the Privacy Policy in an orderly manner and shall
                        be used by either Party to
                        interpret the provisions contained herein in a manner as may apply to the User.
                        Further, it is specifically
                        agreed to by the Parties that the headings shall have legal or contractual value on
                        the User availing the
                        services from the Platform.
                    </li>
                    <li> The use of this Platform by the Users is solely governed by these Terms, Privacy
                        Policy and as well as other
                        policies that may be uploaded on the platform the purpose of the Users effective
                        applicability, and any
                        modifications or amendments made thereto by the Company, from time to time, at its
                        sole discretion. If you as a
                        User continue to access and use this Platform, you are agreeing to comply with and
                        be bound by the following
                        Terms and Conditions, Privacy Policy and such other policy as that may be available
                        and applicable on the Users
                        of the Platform.
                    </li>
                    <li> The User expressly agrees and acknowledges that these Terms and Policy are
                        co-terminus in nature and that
                        expiry/termination of either one will lead to the termination of the other.
                    </li>
                    <li> The User unequivocally agrees that these Terms and the aforementioned Policies
                        constitute a legally binding
                        agreement between the User and the Company, and that the User shall be subject to
                        the rules, guidelines,
                        policies, terms, and conditions applicable to any service that is provided by the
                        Platform, and that the same
                        shall be deemed to be incorporated into these Terms, and shall be treated as part
                        and parcel of the same. The
                        User acknowledges and agrees that no signature or express act is required to make
                        these Terms and the Policy
                        binding on the User and that the User’s act of visiting any part of the Platform
                        constitutes the User’s full and
                        final acceptance of these Terms and the aforementioned Policy.
                    </li>
                    <li> The Company reserves the sole and exclusive right to amend or modify these Terms
                        without any prior permission
                        or intimation to the User, and the User expressly agrees that any such amendments or
                        modifications shall come
                        into effect immediately. If the User does not adhere to the changes, they must stop
                        using the Services at once.
                        Their continuous use of the Services will signify your acceptance of the changed
                        terms.
                    </li>
                </ul>
                <h3><strong>PLATFORM OVERVIEW</strong></h3>
                <p>
                    The Platform deals in providing teaching and training services to the students of
                    Institute of Ultrasound Training
                    based out of New Delhi, India. The services shall be provided through video links, notes
                    in the form of PDF and word
                    document. The Institute is solely dedicated to education, training and research in
                    Diagnostic ultrasound and Color
                    Doppler since 2000. The Institute of Ultrasound Training is an affiliate education
                    center of Jefferson Ultrasound
                    Radiology & Education Institute, Thomas Jefferson University Hospital, Philadelphia,
                    USA- the only collaborating
                    center in the world recognized by World Health Organization (WHO) for imparting training
                    & Education in diagnostic
                    ultrasound.

                </p>
                <h3><strong>REGISTRATION</strong></h3>
                <p>Registration is mandatory for all users of the platform. The Registrations shall be
                    deemed complete only upon the
                    user's authentication and verification of the user's details through the mobile or email
                    authentication. You are
                    required to register Yourself on the Platform in order to avail the Platform and the
                    courses listed on Platform. For
                    the purpose registration, the User shall provide the following details on the platforms
                    in order to fill up the
                    registration form. The Registrations shall be deemed complete only upon the user's
                    authentication and verification
                    of the user's details through the mobile or email authentication.
                </p>
                <ul>
                    <li>Name</li>
                    <li>Email-ID</li>
                    <li>Mobile Number</li>
                </ul>
                <p>
                    Further, the Platform provides services to the User on subscription, wherein the company
                    provides access to users
                    from a backend. The users shall subscribe in order to use the Platform by using login
                    credentials and can further
                    access video courses and assessments related to medical practice listed on the Platform.
                </p>
                <h3><strong>ELIGIBILITY</strong></h3>
                <ul>
                    <li>The Users jointly represents and warrants that they are competent and eligible to
                        enter into legally binding
                        agreements and of age and that they have the requisite authority to bind themselves
                        to these Terms following the
                        Law.
                    </li>
                    <li> The Users further represents that they will comply with this Agreement and all
                        applicable local, state,
                        national and international laws, rules and regulations.
                    </li>
                    <li> The Users may not use the Platform if they are not competent to contract or are
                        disqualified from doing so by
                        any other applicable law, rule or regulation currently in force.
                    </li>
                </ul>
                <h3><strong>CONTENT</strong></h3>
                <p>All text, graphics, User interfaces, visual interfaces, photographs, trademarks, logos,
                    brand names, descriptions,
                    (collectively, ‘Content’), is generated/provided or based on information provided by the
                    users or third parties and
                    the Platform has no control and make no guarantees regarding the quality, the accuracy,
                    integrity or genuineness of
                    such content or such other information provided on the Platform.
                </p>
                <p>All the Content displayed on the Platform is subject to copyright and shall not be reused
                    by any party (or a third
                    party) without the prior written consent of the Company and the copyright owner.
                </p>
                <p>The Users are solely responsible for the integrity, authenticity, quality and genuineness
                    of the content provided on
                    the Platforms and whilst feedback and comments by Users can be made via the Platform,
                    the Platform bears no
                    liability whatsoever for any feedback or comments made by the Users or made in respect
                    of any of the content on the
                    Platform. Further, the Platform reserves its right to suspend the account of any User
                    for an indefinite period to be
                    decided at the discretion of the Platform or to terminate the account of any User who is
                    found to have created or
                    shared or submitted any Content or part thereof that is found to be
                    untrue/inaccurate/misleading or
                    offensive/vulgar. The User shall be solely responsible for making good any financial or
                    legal losses incurred
                    through the creation/sharing/submission of Content or part thereof that is deemed to be
                    untrue/inaccurate/misleading.
                </p>
                <p>The Users have a personal, non-exclusive, non-transferable, revocable, limited privilege
                    to access the
                    Content on the Platform. Users shall not copy, adapt, and modify any content without
                    written permission of the
                    Company.
                </p>
                <h3><strong>INDEMNITY</strong></h3>
                <p>The Users of this Platform agree to indemnify, defend and hold harmless the
                    Company/Platform, and their respective
                    directors, officers, employees and agents (collectively, <strong>"Parties"</strong>),
                    from and against any losses,
                    liabilities, claims, damages, demands, costs and expenses (including legal fees and
                    disbursements in connection
                    therewith and interest chargeable thereon) asserted against or incurred by us that arise
                    out of, result from, or
                    maybe payable by, any breach or non-performance of any representation, warranty,
                    covenant or agreement made or
                    obligation to be performed according to these terms of use. Further, the User agrees to
                    hold the Company/Platform
                    harmless against any claims made by any third party due to, or arising out of, or in
                    connection with:
                </p>
                <ul>
                    <li>User’s use of the Platform, </li>
                    <li>User’s violation of these Terms and Conditions; </li>
                    <li>User’s violation of any rights of another;</li>
                    <li>User’s alleged improper conduct according to these Terms;</li>
                    <li>User’s conduct in connection with the Platform;</li>
                </ul>
                <p>
                    User agrees to fully cooperate in indemnifying the Company and the Platform at the
                    user’s expense. The user also
                    agrees not to settle with any party without the consent of the Company.
                </p>
                <p>In no event shall the Company be liable to compensate the User or any third party for any
                    special, incidental,
                    indirect, consequential or punitive damages whatsoever, including those resulting from
                    loss of use, data or profits,
                    whether or not foreseeable, and whether or not the Company/ Platform had been advised of
                    the possibility of such
                    damages, or based on any theory of liability, including breach of contract or warranty,
                    negligence or other tortuous
                    action, or any other claim arising out of or in connection with the User’s use of or
                    access to the Platform and/or
                    the Services or materials contained therein.
                </p>
                <h3><strong>LIMITATION OF LIABILITY</strong></h3>
                <ul>
                    <li>
                        The Founders/ Promoters/ Partners/ Associated people of the Company/Platform are not
                        responsible for any
                        consequences arising out of the following events:
                        <ul>
                            <li>
                                If the Platform is inoperative/non-responsive due to any connectivity errors
                                associated with the
                                internet connection such as but not limited to slow connectivity, no
                                connectivity, server failure;
                            </li>
                            <li>If the User has fed incorrect information or data or for any deletion of
                                data;
                            </li>
                            <li>If there is an undue delay or inability to communicate through email;
                            </li>
                            <li>If there is any deficiency or defect in the Services managed by Us;
                            </li>
                            <li>If there is a failure in the functioning of any other service provided by
                                the Platform.
                            </li>
                        </ul>
                    </li>
                    <li>
                        The Platform accepts no liability for any errors or omissions, on behalf of itself,
                        or for any damage caused to
                        the User, the User’s belongings, or to any third party, resulting from the use or
                        misuse of the Platform or any
                        service availed of by the User through the Platform. The service and any Content or
                        material displayed on the
                        service is provided without any guarantees, conditions or warranties as to its
                        accuracy, suitability,
                        completeness or reliability. The Platform will not be liable to you for the
                        unavailability or failure of the
                        Platform.
                    </li>
                    <li>Users are to comply with all laws applicable to them or their activities, and with
                        all Policies, which are
                        hereby incorporated into this Agreement by reference.
                    </li>
                    <li>The Platform expressly excludes any liability for any loss or damage that was not
                        reasonably foreseeable by the
                        Platform and which is incurred by you in connection with the Platform, including
                        loss of profits; and any loss
                        or damage incurred by you as a result of your breach of these terms.

                    </li>
                </ul>
                <p>To the fullest extent permitted by law, the Platform shall not be liable to you or any
                    other party for any loss or
                    damage, regardless of the form of action or basis of any claim. You acknowledge and
                    agree that your sole and
                    exclusive remedy for any dispute with us is to terminate your use of the Platform.
                </p>
                <h3><strong>TERM</strong></h3>
                <ul>
                    <li>These Terms shall continue to form a valid and binding contract between the Parties
                        and shall continue to be in
                        full force and effect until the User continues to access and use the Platforms.
                    </li>
                    <li>The Users may terminate their use of the Platform at any time.
                    </li>
                    <li>The Company may terminate these Terms and close any account at any time without
                        notice and/or suspend or
                        terminate a User’s access to the Platform at any time and for any reason, if any
                        discrepancy or legal issue
                        arises.
                    </li>
                    <li>Such suspension or termination shall not limit our right to take any other action
                        against you that the Company
                        considers appropriate.
                    </li>
                    <li>It is also hereby declared that the Company may discontinue the Services and
                        Platforms without any prior notice.
                    </li>
                </ul>
                <h3><strong>TERMINATION</strong></h3>
                <ul>
                    <li>The Company reserves the right, in its sole discretion, to unilaterally terminate
                        the User’s access to the
                        Platform, or any portion thereof, at any time, without notice or cause.
                    </li>
                    <li> The Platform also reserves the universal right to deny access to particular users,
                        to any/all of are on its
                        Platform without any prior notice/explanation to protect the interests of the
                        Platform and/or other visitors to
                        the Platform.
                    </li>
                    <li> The Platform reserves the right to limit, deny or create different access to the
                        Platform and its features
                        concerning different Users, or to change any of the features or introduce new
                        features without prior notice.
                    </li>
                    <li> The User shall continue to be bound by these Terms, and it is expressly agreed to
                        by the Parties that the User
                        shall not have the right to terminate these Terms till the expiry of the same.
                    </li>
                </ul>
                <h3><strong>COMMUNICATION</strong></h3>
                <p>By using this Platform and providing his/her identity and contact information to the
                    Company through the Platform,
                    the Users hereby agree and consent to receive calls, e-mails or SMS from the Company
                    and/or any of its
                    representatives at any time. (only with users consent)
                </p>
                <p> Users can report to “iout364@yahoo.com” if they find any discrepancy with regard to
                    Platform or content-related
                    information and the Company will take necessary action after an investigation. The
                    response with resolution (if any
                    issues found) shall be dependent on the time is taken for investigation.
                </p>
                <p>The User expressly agrees that notwithstanding anything contained hereinabove, it may be
                    contacted by the Company or
                    any representatives relating to any services availed by the User on the Platform or
                    anything pursuant thereto and
                    the Users agrees to indemnify the Company from any harassment claims. It is expressly
                    agreed to by the Parties that
                    any information shared by the User with the Company shall be governed by the Privacy
                    Policy.
                </p>
                <h3><strong>USER OBLIGATIONS AND FORMAL UNDERTAKINGS AS TO CONDUCT</strong></h3>
                <p> The User agrees and acknowledges that they are a restricted user of this Platform and
                    that they:
                </p>
                <ul>
                    <li>Agree to ensure the Name, Email address, Address, Mobile number and any such other
                        information is valid at all
                        times and shall keep your information accurate and up-to-date.
                    </li>
                    <li>Users agree that they are solely responsible for maintaining the confidentiality of
                        your account password. You
                        agree to notify us immediately of any unauthorized use of your account. Company
                        reserves the right to close your
                        account at any time for any or no reason.
                    </li>
                    <li>Understand and acknowledge that the data submitted is manually entered into the
                        database of the Platform. The
                        User also acknowledges the fact that data so entered into the database is for easy
                        and ready reference for the
                        User, and to streamline the Services through the Platform.
                    </li>
                    <li>Authorize the Platform to use, store or otherwise process certain personal
                        information and all published
                        Content, responses, locations, User comments, reviews and ratings for
                        personalization of Services, marketing and
                        promotional purposes and for optimisation of User-related options and Services.
                    </li>
                    <li>Understand and agree that, to the fullest extent permissible by law, the
                        Platform/Company and their successors
                        and assigns, or any of their affiliates or their respective officers, directors,
                        employees, agents, licensors,
                        representatives, operational service providers, advertisers or suppliers shall not
                        be liable for any loss or
                        damage, of any kind, direct or indirect, in connection with or arising from the use
                        of the Platform or this
                        terms of use, including, but not limited to, compensatory, consequential,
                        incidental, indirect, special or
                        punitive damages.
                    </li>
                    <li> Are bound not to cut, copy, modify, recreate, reverse engineer, distribute,
                        disseminate, post, publish or
                        create derivative works from, transfer, or sell any information or obtained from the
                        Platform. Any such
                        use/limited use of the Platform will only be allowed with the prior express written
                        permission of the Company.
                    </li>
                    <li>Agree not to access (or attempt to access) the Platform and/or the materials or
                        Services by any means other than
                        through the interface provided by the Platform. The use of deep-link, robot, spider
                        or other automatic devices,
                        program, algorithm or methodology, or any similar or equivalent manual process, to
                        access, acquire, copy or
                        monitor any portion of the Platform or its content, or in any way reproduce or
                        circumvent the navigational
                        structure or presentation of the Platform, materials or any content, or to obtain or
                        attempt to obtain any
                        materials, documents or information through any means not specifically made
                        available through the Platform will
                        lead to suspension or termination of the User’s access to the Platform. The User
                        acknowledges and agrees that by
                        accessing or using the Platform or any of the Services provided therein, it may be
                        exposed to content that it
                        may consider offensive, indecent or otherwise objectionable. The Company disclaims
                        any liabilities arising
                        concerning such offensive content on the Platform.
                    </li>
                    <li>Expressly agree and acknowledge that the Content generated by the Users and
                        displayed on the Platform is not
                        owned by the Company and that the Company is in no way responsible for the content
                        of the same. The User may,
                        however, report any offensive or objectionable content, which the Company may then
                        remove from the Platform, at
                        its sole discretion.
                    </li>
                    <li>Expressly consents to follow the terms and conditions, and policies of the Vendor
                        affiliated with the Company
                        from whom the Users are utilizing services.

                    </li>
                </ul>
                <p>The User further undertakes not to: </p>
                <ul>
                    <li>
                        Engage in any activity that interferes with or disrupts access to the Platform or
                        the Services provided therein
                        (or the servers and networks which are connected to the Platform);
                    </li>
                    <li>Impersonate any person or entity, or falsely state or otherwise misrepresent his/her
                        affiliation with a person
                        or entity;
                    </li>
                    <li>Probe, scan or test the vulnerability of the Platform or any network connected to
                        the Platform, nor breach the
                        security or authentication measures on the Platform or any network connected to the
                        Platform. The User may not
                        reverse look-up, trace or seek to trace any information relating to any other User
                        of, or visitor to, the
                        Platform, or any other viewer of the Platform, including any User account maintained
                        on the Platform not
                        operated/managed by the User, or exploit the Platform or information made available
                        or offered by or through the
                        Platform, in any manner;
                    </li>
                    <li>Disrupt or interfere with the security of, or otherwise cause harm to, the Platform,
                        systems resources,
                        accounts, passwords, servers, or networks connected to or accessible through the
                        Platform or any affiliated or
                        linked Platforms.
                    </li>
                    <li>Use the Platform or any material or content therein for any purpose that is unlawful
                        or prohibited by these
                        Terms, or to solicit the performance of any illegal activity or other activity which
                        infringes the rights of
                        this Platform or any other third party (s);
                    </li>
                    <li>Violate any code of conduct or guideline which may apply for or to any particular
                        service offered on the
                        Platform;
                    </li>
                    <li>Violate any applicable laws, rules or regulations currently in force within or
                        outside India;
                    </li>
                    <li>Violate any portion of these Terms or the Privacy Policy, including but not limited
                        to any applicable additional
                        terms of the Platform contained herein or elsewhere, whether made by amendment,
                        modification, or otherwise;
                    </li>
                    <li>Commit any act that causes the Company to lose (in whole or in part) the Services of
                        its Internet Establishment
                        ("ISP") or in any manner disrupts the Services of any other supplier/service
                        provider of the Company/Platform;
                    </li>
                </ul>
                <p> Further: </p>
                <ul>
                    <li>
                        The User hereby expressly authorizes the Company/Platform to disclose any and all
                        information relating to the
                        User in the possession of the Company/Platform to law enforcement or other
                        government officials, as the Company
                        may in its sole discretion, believe necessary or appropriate in connection with the
                        investigation and/or
                        resolution of possible crimes, especially those involve personal injury and
                        theft/infringement of intellectual
                        property. The User further understands that the Company/Platform might be directed
                        to disclose any information
                        (including the identity of persons providing information or materials on the
                        Platform) as necessary to satisfy
                        any judicial Order, law, regulation or valid governmental request.
                    </li>
                    <li>The User agrees to use the services provided by the Company, its affiliates,
                        consultants and contracted
                        companies, for lawful purposes only.
                    </li>
                    <li>The User agrees not to make any bulk purchase to indulge in any resale activities.
                        In case of any such
                        instances, the Company reserves all rights to cancel the current and future orders
                        and block the concerned User
                        account.
                    </li>
                    <li>The User agrees to provide authentic and true information. The Company reserves the
                        right to confirm and
                        validate the information and other details provided by the User at any point of
                        time. If upon confirmation such
                        User details are found to be false, not to be true (wholly or partly), the Company
                        shall in its sole discretion
                        reject and debar the User from using the Services available on its Website, and/or
                        other affiliated websites
                        without prior intimation whatsoever.

                    </li>
                    <li>The User agrees not to post any material on the website that is defamatory,
                        offensive, obscene, indecent,
                        abusive, or needlessly distressful, or advertising any goods or services. More
                        specifically, the User agrees not
                        to host, display, upload, update, publish, modify, transmit, or in any manner share
                        any information that:
                        <ul>
                            <li>
                                belongs to another person and to which the User has no right to;
                            </li>
                            <li> is grossly harmful, harassing, blasphemous, defamatory, obscene,
                                pornographic, pedophilic, libellous,
                                invasive
                                of another's privacy, hateful, or racially, ethnically objectionable,
                                disparaging, relating or
                                encouraging money
                                laundering or gambling, or otherwise unlawful in any manner whatever;
                            </li>
                            <li> is in any way harmful to minors;
                            </li>
                            <li> infringes any patent, trademark, copyright or other proprietary rights;
                            </li>
                            <li> violates any law for the time being in force;
                            </li>
                            <li> deceives or misleads the addressee about the origin of such messages or
                                communicates any information
                                which is
                                grossly offensive or menacing;
                            </li>
                            <li> Abuse, harass, threaten, defame, disillusion, erode, abrogate, demean or
                                otherwise violate the legal
                                rights of
                                others;
                            </li>
                            <li> Impersonate any person or entity, or falsely state or otherwise
                                misrepresent Your affiliation with a
                                person or
                                entity;
                            </li>
                            <li> Publish, post, disseminate, any grossly harmful information, harassing,
                                blasphemous, defamatory,
                                obscene,
                                pornographic, pedophilic, libellous, invasive of another's privacy, hateful,
                                or racially, ethnically
                                objectionable, disparaging, relating or encouraging money laundering or
                                gambling, or otherwise unlawful
                                in any
                                manner whatever; or unlawfully threatening or unlawfully harassing including
                                but not limited to
                                "indecent
                                representation of women" within the meaning of the Indecent Representation
                                of Women (Prohibition) Act,
                                1986;
                            </li>
                            <li> Threatens the unity, integrity, defence, security or sovereignty of India,
                                friendly relations with
                                foreign
                                states, or public order or causes incitement to the commission of any
                                cognizable offence or prevents
                                investigation of any offence or is insulting any other nation.

                            </li>
                        </ul>
                    </li>
                </ul>

                <h3><strong>SUSPENSION OF USER ACCESS AND ACTIVITY</strong></h3>
                <p>Notwithstanding other legal remedies that may be available, the Company may in its sole
                    discretion, limit the User’s
                    access and/or activity by immediately removing the User’s access credentials either
                    temporarily or indefinitely, or
                    suspend/terminate the User’s association with the Platform, and/or refuse to the usage
                    of the Platform to the User,
                    without being required to provide the User with notice or cause:
                </p>
                <ul>
                    <li>If the User is in breach any of these Terms or the Privacy Policy;
                    </li>
                    <li> If the User has provided wrong, inaccurate, incomplete or incorrect information;
                    </li>
                </ul>
                <p>If the User’s actions may cause any harm, damage or loss to the other Users or the
                    Company, at the sole discretion of
                    the Company.</p>

                <h3><strong>INTELLECTUAL PROPERTY RIGHTS</strong></h3>
                <p>Unless expressly agreed to in writing, nothing contained herein shall give the User a
                    right to use any of the
                    Platform’s trade names, trademarks, service marks, logos, domain names, information,
                    questions, answers, solutions,
                    reports and other distinctive brand features, save according to the provisions of these
                    Terms. All logos, trademarks,
                    brand names, service marks, domain names, including material, designs, and graphics
                    created by and developed by the
                    Platform and other distinctive brand features of the Platform are the property of the
                    Company or the respective
                    copyright or trademark owner. Furthermore, concerning the Platform created by the
                    Company, the Company shall be the
                    exclusive owner of all the designs, graphics and the like, related to the Platform.
                </p>
                <p>The User may not use any of the intellectual property displayed on the Platform in any
                    manner that is likely to cause
                    confusion among existing or prospective Users of the Platform, or that in any manner
                    disparages or discredits the
                    Company/Platform, to be determined in the sole discretion of the Company.
                </p>
                <p>The User is aware all intellectual property, including but not limited to copyrights,
                    relating to said services
                    resides with the owners, and that at no point does any such intellectual property stand
                    transferred from the
                    aforementioned creators to the Company or any other User. The User is aware that the
                    Company merely provides a
                    platform through which the Users can communicate and schedule meetings, and the
                    Company/Platform does not own any of
                    the intellectual property relating to the independent content displayed on the Platform,
                    apart from created graphics
                    and specified content.
                </p>
                <p>The User is further aware that any reproduction or infringement of the intellectual
                    property of the aforementioned
                    owners by the User will result in legal action being initiated against the User by the
                    respective owners of the
                    intellectual property so reproduced/infringed upon. It is agreed to by the Parties that
                    the contents of this Section
                    shall survive even after the termination or expiry of the Terms and/or Policy.
                </p>
                <h3><strong>DISCLAIMER OF WARRANTIES AND LIABILITIES</strong></h3>
                <ul>
                    <li>
                        The User agrees and undertakes that they are accessing the Platform at their sole
                        risk and are that they are
                        using their best and prudent judgment before availing any service listed on the
                        Platform or accessing/using any
                        information displayed thereon.
                    </li>
                    <li> The User agrees that any kind of information, resources, activities,
                        recommendations obtained/availed from
                        Platform, written or oral, will not create any warranty and the Platform disclaims
                        all liabilities resulting
                        from these.
                    </li>
                    <li> The Platform does not guarantee that the Services contained in the Platform will be
                        uninterrupted or
                        error-free, or that the Platform or its server will be free of viruses or other
                        harmful components, and the User
                        hereby expressly accepts any associated risks involved with the User’s use of the
                        Platform.
                    </li>
                </ul>
                <p>It is further agreed to by the Parties that the contents of this Section shall survive
                    even after the
                    termination or expiry of the Terms and/or Policy.</p>
                <h3><strong>FORCE MAJEURE</strong></h3>
                <p>Neither the Company nor the Platform shall be liable for damages for any delay or failure
                    to perform its obligations
                    hereunder if such delay or failure is due to cause beyond its control or without its
                    fault or negligence, due to
                    Force Majeure events including but not limited to acts of war, acts of God, earthquake,
                    riot, fire, festive
                    activities sabotage, labour shortage or dispute, internet interruption, technical
                    failure, breakage of sea cable,
                    hacking, piracy, cheating, illegal or unauthorized.
                </p>
                <h3><strong>DISPUTE RESOLUTION AND JURISDICTION
                    </strong></h3>
                <p>It is expressly agreed to by the Parties hereto that the formation, interpretation, and
                    performance of these Terms
                    and any disputes arising therefrom will be resolved through a two-step Alternate Dispute
                    Resolution (“ADR”)
                    mechanism. It is further agreed to by the Parties that the contents of this Section
                    shall survive even after the
                    termination or expiry of the Terms and/or Policy. </p>
                <ul>
                    <li><strong>Mediation:</strong> In case of any dispute between the parties, the Parties
                        will attempt to resolve the
                        same amicably amongst themselves, to the mutual satisfaction of all parties. In the
                        event that the Parties are
                        unable to reach such an amicable solution within thirty (30) days of one Party
                        communicating the existence of a
                        dispute to any other Party, the dispute will be resolved by arbitration, as detailed
                        hereinbelow;
                    </li>
                    <li> <strong> Arbitration:</strong> In the event that the Parties are unable to amicably
                        resolve a dispute by
                        mediation, said dispute will be referred to arbitration by a sole arbitrator to be
                        appointed by the Company, and
                        the award passed by such sole arbitrator will be valid and binding on all parties.
                        The Parties shall bear their
                        own costs for the proceedings, although the sole arbitrator may, in his/her sole
                        discretion, direct either Party
                        to bear the entire cost of the proceedings. The arbitration shall be conducted in
                        English, and the seat of
                        Arbitration shall be the city of <strong> New Delhi, India.</strong>
                    </li>
                </ul>
                <p>The Parties expressly agree that the Terms of Service, Privacy Policy and any other
                    agreements entered into between
                    the Parties are governed by the laws, rules, and regulations of India.</p>
                <h3><strong>NOTICES/GRIEVANCES</strong></h3>
                <p>Any and all communication relating to any dispute or grievance experienced by the User
                    may be communicated to the
                    Company by the User by emailing to iout364@yahoo.com</p>
                <h3><strong>MISCELLANEOUS PROVISIONS</strong></h3>
                <ul>
                    <li>
                        <strong>Entire Agreement:</strong> These Terms, read with the Policy, form the
                        complete and final contract
                        between the User and
                        the Company with respect to the subject matter hereof and supersedes all other
                        communications, representations,
                        and agreements (whether oral, written or otherwise) relating thereto.
                    </li>
                    <li> <strong>Waiver:</strong> The failure of either Party at any time to require
                        performance of any provision of
                        these Terms shall in
                        no manner affect such Party's right at a later time to enforce the same. No waiver
                        by either party of any breach
                        of these Terms, whether by conduct or otherwise, in any one or more instances, shall
                        be deemed to be or
                        construed as a further or continuing waiver of any such breach, or a waiver of any
                        other breach of these Terms.
                    </li>
                    <li> <strong>Severability:</strong> If any provision/clause of these Terms is held to be
                        invalid, illegal or
                        unenforceable by any
                        court or authority of competent jurisdiction, the validity, legality, and
                        enforceability of the remaining
                        provisions/clauses of these Terms shall in no way be affected or impaired thereby,
                        and each such
                        provision/clause of these Terms shall be valid and enforceable to the fullest extent
                        permitted by law. In such
                        case, these Terms shall be reformed to the minimum extent necessary to correct any
                        invalidity, illegality or
                        unenforceability, while preserving to the maximum extent the original rights,
                        intentions and commercial
                        expectations of the Parties hereto, as expressed herein.
                    </li>
                    <li> <strong>Contact Us:</strong> If you have any questions about this Policy or any
                        other policy, the practices of
                        the Platform, or
                        your experience, you can contact us at info@ultrasound.net.in
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>