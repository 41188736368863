<div id="wrapper">
    <div class="inner-banner courses_banner" style="background-image: url(assets/images/courses-banner.png);">
        <div class="container">
            <div class="banner-row">
                <h1 class="page-title">Online & On Campus Courses Both</h1>
                <!-- <a class="btn" href="contact-us">Contact us</a> -->
            </div>
        </div>
    </div>
    <div class="content">
        <div class="secrion_courses">
            <div class="container">
                <h2 class="section-title">My Courses</h2>
                <div class="row" [hidden]="mycourseDetails?.length">
                    <div class="col33">

                        <div class="courses-box">
                            <div class="courses-image">
                                <div class="courses-bg" style="padding-bottom: 1px !important;">
                                    <ngx-skeleton-loader appearance="round" [theme]="{ width: '80px', height: '80px' }">
                                    </ngx-skeleton-loader>
                                </div>
                            </div>
                            <div class="courses-info">

                                <h3 class="post-title">
                                    <a href="javascript:void(0)">
                                        <ngx-skeleton-loader
                                            [theme]="{width: '200px','border-radius': '0',height: '15px','margin-bottom': '10px'}">
                                        </ngx-skeleton-loader>
                                    </a>
                                </h3>
                                <div class="post-info">
                                    <span class="doctor-name">
                                        <ngx-skeleton-loader
                                            [theme]="{width: '200px','border-radius': '0',height: '15px','margin-bottom': '10px'}">
                                        </ngx-skeleton-loader>
                                    </span>
                                    <span class="post-date">
                                        <ngx-skeleton-loader
                                            [theme]="{width: '200px','border-radius': '0',height: '15px','margin-bottom': '10px'}">
                                        </ngx-skeleton-loader>
                                    </span>
                                    <!-- <span class="post-date">
                                        <ngx-skeleton-loader
                                            [theme]="{width: '200px','border-radius': '0',height: '15px','margin-bottom': '10px'}">
                                        </ngx-skeleton-loader>
                                    </span> -->
                                </div>
                                <!-- <a class="btn" (click)="sendId(data?._id)" [disabled]="data.isExpired">View
                                                            details12ll</a> -->
                                <!-- <button [disabled]="data.isExpired" (click)="sendId(data?._id)" class="btn">View
                                                        details</button> -->
                            </div>
                        </div>

                    </div>
                    <div class="col33">

                        <div class="courses-box">
                            <div class="courses-image">
                                <div class="courses-bg" style="padding-bottom: 1px !important;">
                                    <ngx-skeleton-loader appearance="round" [theme]="{ width: '80px', height: '80px' }">
                                    </ngx-skeleton-loader>
                                </div>
                            </div>
                            <div class="courses-info">

                                <h3 class="post-title">
                                    <a href="javascript:void(0)">
                                        <ngx-skeleton-loader
                                            [theme]="{width: '200px','border-radius': '0',height: '15px','margin-bottom': '10px'}">
                                        </ngx-skeleton-loader>
                                    </a>
                                </h3>
                                <div class="post-info">
                                    <span class="doctor-name">
                                        <ngx-skeleton-loader
                                            [theme]="{width: '200px','border-radius': '0',height: '15px','margin-bottom': '10px'}">
                                        </ngx-skeleton-loader>
                                    </span>
                                    <span class="post-date">
                                        <ngx-skeleton-loader
                                            [theme]="{width: '200px','border-radius': '0',height: '15px','margin-bottom': '10px'}">
                                        </ngx-skeleton-loader>
                                    </span>
                                    <!-- <span class="post-date">
                                        <ngx-skeleton-loader
                                            [theme]="{width: '200px','border-radius': '0',height: '15px','margin-bottom': '10px'}">
                                        </ngx-skeleton-loader>
                                    </span> -->
                                </div>
                                <!-- <a class="btn" (click)="sendId(data?._id)" [disabled]="data.isExpired">View
                                                                                details12ll</a> -->
                                <!-- <button [disabled]="data.isExpired" (click)="sendId(data?._id)" class="btn">View
                                                                            details</button> -->
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col33" *ngFor="let data of mycourseDetails">

                        <div class="courses-box">
                            <div class="courses-image">
                                <div class="courses-bg" style="background-image: url({{data.image}});"></div>
                            </div>
                            <div class="courses-info">

                                <h3 class="post-title">
                                    <a href="javascript:void(0)"
                                        style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                                        title="{{data.courseName}}" (click)="sendId(data)">{{data.courseName
                                        | titlecase}}</a>
                                </h3>
                                <div class="post-info">
                                    <span class="doctor-name">{{(data?.instructor | titlecase) ||
                                        'N/A'}}</span>
                                    <span class="post-date" *ngIf="data.isExpired == false">{{data?.expiredIn || 'N/A'}}
                                        Days left</span>
                                    <span class="post-date" *ngIf="data.isExpired">Course expired</span>
                                </div>
                                <button [disabled]="data.isExpired" (click)="sendId(data)" class="btn">View
                                    details</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>