import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GenericService {
  public isLogin: boolean = false;
  public userInfo: any = [];
  public token: any;
  constructor() {
    this.getLogin();
  }

  async getLogin() {

    let profile = JSON.parse(localStorage.getItem('userinfo'));
    if (profile != null) {
      this.isLogin = true;
      this.userInfo = profile;
      // this.token = JSON.parse(localStorage.getItem('token'));
    }
  }
}

