import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // baseUrl = "https://api.test.drrandhawainstitute.com/api/v1";  // Api baseUrl 
  baseUrl = environment.baseUrl;  // Api baseUrl 

  constructor(private HttpClient: HttpClient,) {

  }


  ngOnInit(): void {
  }


  //  Post Api function **//
  postApi(url, data, isHeader): Observable<any> {
    if (isHeader == 1) {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem('token')
          //  "Authorization": "JWT " + JSON.parse(localStorage.getItem('token'))
        }),
        observe: 'response'
      }
    }

    else if (isHeader == 2) {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          "Authorization": localStorage.getItem('token')
        }),
        observe: 'response'
      }
    }
    else if (isHeader == 3) {
      let httpHeader = new HttpHeaders({ "Authorization": localStorage.getItem('token') });

      httpHeader.append('content-type', 'multipart/form-data');
      var httpOptions: any = {
        headers: httpHeader
      }
    }

    else {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
        observe: 'response'
      }
    }
    return this.HttpClient.post((this.baseUrl + url), data, httpOptions)
  }

  //  Get Api function **//
  getApi(url, isHeader): Observable<any> {
    if (isHeader == 1) {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem('token')
        }),
        observe: 'response'
      }
    }

    else if (isHeader == 2) {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          "Authorization": localStorage.getItem('token')
        }),
        observe: 'response'
      }
    }
    else {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
        observe: 'response'
      }
    }
    return this.HttpClient.get((this.baseUrl + url), httpOptions)

  }

  //  Put Api function **//
  putApi(url, obj: any, isHeader): Observable<any> {

    if (isHeader == 1) {

      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem('token')
        }),
        observe: 'response'


      }
      // console.log("hjhjhjhj",JSON.parse(localStorage.getItem('token')));
    } else if (isHeader == 2) {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          "Authorization": localStorage.getItem('token')
        }),
        observe: 'response'
      }
    } else if (isHeader == 3) {
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "multipart/form-data",
          "Authorization": localStorage.getItem('token')
          // console.log("hjhjhjhj",JSON.parse(localStorage.getItem('token')));

        })
      }

    }
    else {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",

        }),
        observe: 'response'
      }
    }
    return this.HttpClient.put(this.baseUrl + url, obj, httpOptions)
  }

}
