export const endpoint = {    // Apis Endpoints
        login: "/login",
        forgotPassword: "/forgot-password",
        resetPassword: "/reset-password",
        changePassword: "/change-password",
        myCourse: "/my-courses",
        profile: "/profile",
        editProfile: "/edit-profile",
        chapter: "/chapters/",
        logOut: "/logout",
        chapterDetails: "/chapter/",
        questionanswer: "/chapter/",
        takeassessment: "/assessment/take",
        logout: "/logout"

}
//

