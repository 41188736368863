import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Api_Service/api.service';
import { GenericService } from 'src/app/Api_Service/generic.service';
import { CommonService } from 'src/app/Common_Service/common.service';
import { endpoint } from 'src/app/Constants/endpoints';
import { regex } from 'src/app/Constants/regex';
import { router } from 'src/app/Constants/Router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  showPassword: boolean;
  obje: any;


  constructor(private fb: FormBuilder,
    private apiService: ApiService,
    private commonService: CommonService,
    public generic: GenericService,

    private router: Router) {
    // document.getElementById("wrapper").scrollIntoView();
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      "email": ['', Validators.compose([Validators.required, Validators.maxLength(64), Validators.pattern(regex.email)])],
      "password": ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern(regex.password)])],
    })


  }
  name: string
  showNextComponent(value: string) {

    this.name = value;
  }
  //  PassWord show and hide **//
  showHidePassword() {
    this.showPassword = !this.showPassword;
  }

  //  Login Api function
  login() {

    this.commonService.presentSpinner();
    this.obje = {
      "email": this.loginForm.value.email,
      "password": this.loginForm.value.password,
      "rememberMe": this.loginForm.value.rememberMe
    }
    this.apiService.postApi(endpoint.login, this.obje, 0).subscribe(res => {
      if (res.body.code || res.responseCode == 200) {
        this.generic.userInfo = res.body.userdata;
        this.generic.isLogin = true;
        this.commonService.successToaster(res.body.message)
        this.commonService.dismissSpinner();
        localStorage.setItem('token', res.body.token);
        localStorage.setItem('userinfo', JSON.stringify(res.body.userdata));

        this.router.navigate(['/dashboard/my-course'])
      }
    }, (error) => {
      if (error.error.code == 422) {
        this.commonService.errorToast(error.error.message)

      }
      this.commonService.dismissSpinner();
    })


  }

}

