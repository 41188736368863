import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Api_Service/api.service';
import { CommonService } from 'src/app/Common_Service/common.service';
import { endpoint } from 'src/app/Constants/endpoints';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.css']
})
export class AssessmentComponent implements OnInit {
  chapterid: any;
  questionanswer: any = [];
  assignCourseId: any;
  answer: any = '';
  ShowScoreModal: boolean;
  showInItData: boolean = true;
  pager = {
    index: 0,
    size: 1,
    count: 1
  };
  constructor(
    private commonService: CommonService,
    private ApiService: ApiService,
    private route: ActivatedRoute,
    private location: Location
  ) {

    this.route.queryParams.subscribe(params => {
      this.chapterid = params.chapterid;
      this.assignCourseId = params.assignCourseId;
    });

  }
  goback() {
    this.location.back();
  }
  ngOnInit() {
    this.GetQuestonAnswerList();
    document.getElementById('progress').style.width = '0%';

  }

  a: any = [];
  totallength: any;
  assessmentStatus: any;
  isSubmitted: boolean;
  CorrectAnwser: any;
  totalQuestion: any;
  chapterName: any;
  GetQuestonAnswerList() {

    // this.commonService.presentSpinner();

    this.ApiService.getApi(endpoint.questionanswer + this.chapterid + '?assignCourseId=' + this.assignCourseId, 1).subscribe(res => {
      if (res) {
        if (res.body.code == 200) {
          this.a = res.body.chapterData;
          this.assessmentStatus = this.a.assessmentStatus;
          this.chapterName = this.a.chapterName;
          this.isSubmitted = this.a.isSubmitted;
          this.CorrectAnwser = this.a.totalCorrectAnwser ? this.a.totalCorrectAnwser : '';
          this.totalQuestion = this.a.totalQuestion ? this.a.totalQuestion : '';

          this.questionanswer = res.body.chapterData.assessment;
          this.pager.count = this.questionanswer.length;

          this.questionanswer['totallength'] = this.pager.count
          // for (let i = 0; i < this.questionanswer.length; i++) {
          //   if (this.questionanswer[i].givenAnswer) {
          //     // this.Review(this.pager.index + 1);
          //     this.pager.index;  
          //     this.goTo(this.pager.index + 1, '', '');
          //   }
          // }
          this.questionanswer.forEach(e => {
            e['totallength'] = this.pager.count;
            var obj = e.options;
            const mapped = Object.keys(obj).map(key => ({ type: key, value: obj[key] }));
            e['option2'] = mapped
          });
          if (this.showInItData) {
            document.getElementById('progress').style.width = `${((this.pager.index + 1) / this.pager.count) * 100}%`;

            this.loaddata();
          }
        }
      }
    })
  }

  loaddata() {

    if (this.isSubmitted == false) {
      for (let i = 0; i < this.questionanswer.length; i++) {
        if (this.questionanswer[i].givenAnswer) {
          this.arrData.push(this.questionanswer[i]);
          this.pager.index++;
          document.getElementById('progress').style.width = `${((this.pager.index + 1) / this.totalQuestion) * 100}%`;
          if (this.questionanswer[i].correctAnswer == this.questionanswer[i].givenAnswer) {
            this.totalCorrectAnwser += 1;

          } else {
            this.totalWrongAnwser += 1;
          }
        }
      }
    }

    // console.log("totalCorrectAnwser =" + this.totalCorrectAnwser);
    // console.log("totalWrongAnwser =" + this.totalWrongAnwser);
  }



  get filteredQuestions() {
    return this.questionanswer
      ? this.questionanswer.slice(this.pager.index, this.pager.index + this.pager.size) : [];


  }
  selectanswer: any;
  SelectAnswer(ans, index) {
    this.questionanswer[index].givenAnswer = ans;
    // this.selectanswer = ans;
  }
  totalCorrectAnwser: number = 0
  totalWrongAnwser: number = 0
  arrData: any = [];
  goTo(index: number, item, keydata: any) {

    if (index >= 0 && index < this.pager.count) {
      this.pager.index = index;
      document.getElementById('progress').style.width = `${((this.pager.index + 1) / this.totalQuestion) * 100}%`;
    }

    this.showInItData = false;
    if (item != '' && keydata != '') {
      if (keydata == 'next') {
        document.getElementById('progress').style.width = `${((this.pager.index + 1) / this.pager.count) * 100}%`;

        // item.givenAnswer = this.selectanswer;
        this.arrData.push(item);

        if (item.correctAnswer == item.givenAnswer) {
          this.totalCorrectAnwser += 1;
        } else {
          this.totalWrongAnwser += 1;
        }

        // this.answer = '';
        this.arrData.forEach(e => {
          if (e._id == item._id) {
            var len = this.arrData.length - 1;
            item.givenAnswer = this.arrData[len].givenAnswer;

          } else {
            // item.givenAnswer = '';
          }
        });
        var postdata = {
          "chapterId": this.chapterid,
          "assignCourseId": this.assignCourseId,
          "questionId": item._id,
          "givenAnswer": item.givenAnswer,
          "totalCorrectAnwser": this.totalCorrectAnwser,
          "totalWrongAnwser": this.totalWrongAnwser,
          "isSubmitted": false
        }
        console.log(postdata)




      } else if (keydata == 'previous') {

        var len = this.arrData.length - 1;
        if (this.arrData[len].correctAnswer == this.arrData[len].givenAnswer) {
          this.totalCorrectAnwser -= 1;
        } else {
          this.totalWrongAnwser -= 1;
        }
        // item.givenAnswer = this.arrData[len].givenAnswer;
        this.arrData.splice(len);
        console.log(this.arrData)

        var postdata = {
          "chapterId": this.chapterid,
          "assignCourseId": this.assignCourseId,
          "questionId": item._id,
          "givenAnswer": item.givenAnswer ? item.givenAnswer : '',
          "totalCorrectAnwser": this.totalCorrectAnwser,
          "totalWrongAnwser": this.totalWrongAnwser,
          "isSubmitted": false
        }
        console.log(postdata)

      } else {


        if (item.correctAnswer == item.givenAnswer) {
          this.totalCorrectAnwser += 1;
        } else {
          this.totalWrongAnwser += 1;
        }


        var postdata = {
          "chapterId": this.chapterid,
          "assignCourseId": this.assignCourseId,
          "questionId": item._id,
          "givenAnswer": item.givenAnswer,
          "totalCorrectAnwser": this.totalCorrectAnwser,
          "totalWrongAnwser": this.totalWrongAnwser,
          "isSubmitted": true
        }
      }
      this.ApiService.postApi(endpoint.takeassessment, postdata, 3).subscribe(res => {
        if (res.code) {
          this.GetQuestonAnswerList();
          if (keydata == 'submit') {

            this.ShowScoreModal = true;
          }
          // this.commonService.successToaster(res.message)
          this.commonService.dismissSpinner();
        }
      }, (error) => {
        if (error.error.code == 401) {
          this.commonService.logOut();
          this.commonService.errorToast(error.error.message)

        }
      })
    }




  }

  Review(index: number) {
    if (index >= 0 && index < this.pager.count) {
      this.pager.index = index;
      document.getElementById('progress').style.width = `${((this.pager.index + 1) / this.pager.count) * 100}%`;

    }

  }

  HideScoremodal() {
    this.ShowScoreModal = false;
    this.location.back();
  }



}
