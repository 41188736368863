import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericService } from '../Api_Service/generic.service';
import { CommonService } from '../Common_Service/common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(public router: Router, private service: CommonService, public generic: GenericService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!localStorage.getItem('token')) {
      this.generic.isLogin = false;
      // this.service.errorToast("You don't have permission");
      this.router.navigate(['/dashboard/login']);
      return false
    }
    else {
      return true;
    }

  }

}


