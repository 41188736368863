<div class="inner-banner" style="background-image: url(assets/images/Image_61.png);">
  <div class="container">
    <!-- <h1 class="page-title">Access Institute of Ultrasound Training Courses</h1> -->
  </div>
</div>
<div id="wrapper">
  <div class="content">
    <div class="container">
      <div class="form-login confirm-password">
        <h2 class="form-title">Change Password</h2>
        <p>Please enter your new password and confirm password</p>
        <form [formGroup]="changePasswordForm">
          <div class="mb-3">
            <div class="input input_field">
              <div class="input-icon"><img src="assets/images/password-icon.png"></div>
              <input [type]="showPassword ? 'text' : 'password'" (keydown.space)="$event.preventDefault()"
                class="password" formControlName="oldPassword" name="password" placeholder="Old password">
              <div class="input-group-append password-show"><span class="ca-eye"
                  (click)="showHidePassword('old')"></span>
              </div>
            </div>
            <div class="fonts error-message"
              *ngIf="changePasswordForm.get('oldPassword').hasError('maxlength') && changePasswordForm.get('oldPassword').dirty">
              Maxlength of characters should be 20.
            </div>
            <div class="fonts error-message"
              *ngIf="changePasswordForm.get('oldPassword').hasError('required') && changePasswordForm.get('oldPassword').touched">
              <span class="fonts error-message">Old Password is required.</span>
            </div>
          </div>
          <div class="mb-3">
            <div class="input input_field">
              <div class="input-icon"><img src="assets/images/password-icon.png"></div>
              <input [type]="showNewPassword ? 'text' : 'password'" (keydown.space)="$event.preventDefault()"
                class="password" formControlName="newPassword" name="password" placeholder="New password">
              <div class="input-group-append password-show"><span class="ca-eye"
                  (click)="showHidePassword('new')"></span>
              </div>
            </div>
            <div class="fonts error-message"
              *ngIf="changePasswordForm.get('newPassword').hasError('maxlength') && changePasswordForm.get('newPassword').dirty">
              Maxlength of characters should be 20.
            </div>
            <div
              *ngIf="changePasswordForm.get('newPassword').hasError('required') && changePasswordForm.get('newPassword').touched">
              <span class="fonts error-message">New Password is required.</span>
            </div>
            <div
              *ngIf="changePasswordForm.get('newPassword').hasError('pattern') && changePasswordForm.get('newPassword').dirty">
              <span class="fonts error-message">Password must contain characters, digits and one special character(Min
                character-8).</span>
            </div>
          </div>
          <div class="mb-3">
            <div class="input input_field">
              <div class="input-icon"><img src="assets/images/password-icon.png"></div>
              <input [type]="showConfirmPassword ? 'text' : 'password'" (keydown.space)="$event.preventDefault()"
                class="password" formControlName="confirmPassword" name="password" placeholder="Confirm password">
              <div class="input-group-append password-show"><span class="ca-eye"
                  (click)="showHidePassword('confirm')"></span>
              </div>
            </div>
            <div class="fonts error-message"
              *ngIf="changePasswordForm.get('confirmPassword').hasError('maxlength') && changePasswordForm.get('confirmPassword').dirty">
              Maxlength of characters should be 20.
            </div>
            <div
              *ngIf="changePasswordForm.get('confirmPassword').hasError('required') && changePasswordForm.get('confirmPassword').touched">
              <span class="fonts error-message">Confirm Password is required.</span>
            </div>
            <div
              *ngIf="(changePasswordForm.value.newPassword != changePasswordForm.value.confirmPassword) && changePasswordForm.get('confirmPassword').dirty">
              <span class="fonts error-message">Confirm password & new password should be same.</span>
            </div>
          </div>
          <div class="input button-submit"><input name="button" type="submit" value="Next" (click)="changepassword()"
              [disabled]="!changePasswordForm.valid" id="submit"></div>
        </form>
      </div>
    </div>
  </div>
</div>