<div id="wrapper">
  <div class="inner-banner" style="background-image: url(assets/images/Image_61.png);">
    <div class="container">
      <!-- <h1 class="page-title">Access Institute of Ultrasound Training Courses</h1> -->
    </div>
  </div>
  <div class="content">
    <div class="container">
      <div class="form-login confirm-password">
        <h2 class="form-title">Reset Password</h2>
        <p>Please enter your new password and confirm password</p>
        <form [formGroup]="resetPasswordForm">
          <div class="mb-3">
            <div class="input input_field">
              <div class="input-icon"><img src="assets/images/password-icon.png"></div>
              <input type="password" (keydown.space)="$event.preventDefault()" class="password"
                formControlName="newPassword" name="password" placeholder="New password">
              <div class="input-group-append password-show"><span class="ca-eye"></span></div>
            </div>
            <div class="fonts"
              *ngIf="resetPasswordForm.get('newPassword').hasError('maxlength') && resetPasswordForm.get('newPassword').dirty">
              Maxlength of characters should be 20.
            </div>
            <div
              *ngIf="resetPasswordForm.get('newPassword').hasError('required') && resetPasswordForm.get('newPassword').touched">
              <span class="fonts">New Password is required.</span>
            </div>
            <div
              *ngIf="resetPasswordForm.get('newPassword').hasError('pattern') && resetPasswordForm.get('newPassword').dirty">
              <span class="fonts">Password must contain characters, digits and one special character(Min
                character-8).</span>
            </div>

          </div>
          <div class="mb-3">
            <div class="input input_field">
              <div class="input-icon"><img src="assets/images/password-icon.png"></div><input type="password"
                (keydown.space)="$event.preventDefault()" class="password" formControlName="confirmPassword"
                name="password" placeholder="Confirm password">
              <div class="input-group-append password-show"><span class="ca-eye"></span></div>
            </div>
            <div class="fonts"
              *ngIf="resetPasswordForm.get('confirmPassword').hasError('maxlength') && resetPasswordForm.get('confirmPassword').dirty">
              Maxlength of characters should be 20.
            </div>
            <div
              *ngIf="resetPasswordForm.get('confirmPassword').hasError('required') && resetPasswordForm.get('confirmPassword').touched">
              <span class="fonts">Confirm Password is required.</span>
            </div>
            <div
              *ngIf="(resetPasswordForm.value.newPassword != resetPasswordForm.value.confirmPassword) && resetPasswordForm.get('confirmPassword').dirty">
              <span class="fonts">Confirm password & new password should be same.</span>
            </div>

          </div>
          <div class="input button-submit"><input name="button" type="submit" value="Next" (click)="resetPassword()"
              [disabled]="!resetPasswordForm.valid" id="submit"></div>
        </form>
      </div>
    </div>
  </div>
</div>