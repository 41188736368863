<div id="wrapper">
    <div class="content">
        <div class="chapter-tabs">
            <div class="container">
                <div class="row">
                    <div class="col30">
                        <div class="sidebar-fix">
                            <a class="back-btn" href="javascript:void(0)"
                                [routerLink]="['/dashboard/my-course']">Back</a>
                            <h2 class="section-title">Chapters</h2>
                            <ul id="chapter-nav" [hidden]="chapterList?.length">
                                <li class="active">
                                    <a>
                                        <div class="chapter-box">
                                            <ngx-skeleton-loader appearance="round"
                                                [theme]="{ width: '80px', height: '80px' }">
                                            </ngx-skeleton-loader>
                                            <div class="chapter-right">
                                                <h3 class="chapter-title">
                                                    <ngx-skeleton-loader
                                                        [theme]="{width: '200px','border-radius': '0',height: '15px','margin-bottom': '10px'}">
                                                    </ngx-skeleton-loader>
                                                </h3>
                                            </div>
                                        </div>

                                    </a>
                                </li>
                                <li class="active">
                                    <a>
                                        <div class="chapter-box">
                                            <ngx-skeleton-loader appearance="round"
                                                [theme]="{ width: '80px', height: '80px' }">
                                            </ngx-skeleton-loader>
                                            <div class="chapter-right">
                                                <h3 class="chapter-title">
                                                    <ngx-skeleton-loader
                                                        [theme]="{width: '200px','border-radius': '0',height: '15px','margin-bottom': '10px'}">
                                                    </ngx-skeleton-loader>
                                                </h3>
                                            </div>
                                        </div>

                                    </a>
                                </li>
                            </ul>
                            <ul id="chapter-nav">

                                <li class="active" *ngFor="let data of chapterList;let i=index"
                                    [class.active]="selectedIndex === i" (click)="setIndex(i, 'chapterlist')">
                                    <a (click)="chaperDetails(data?._id)">
                                        <div class="chapter-box">

                                            <span class="chapter-img"
                                                style="background-image: url({{data.image}}); background-size: cover; background-repeat: no-repeat; background-position: center;"></span>
                                            <div class="chapter-right">
                                                <h3 class="chapter-title">
                                                    {{data?.chapterName || 'N/A'}}</h3>
                                            </div>
                                        </div>

                                    </a>
                                </li>
                            </ul> <!-- END tabs-nav -->
                        </div><!-- Sidebar Fix -->
                    </div>
                    <div class="col70">
                        <div class="chapter-content" *ngIf="value==true">
                            <div class="section-video">
                                <div class="video-inner tabs-video">
                                    <div id="videos-content">
                                        <div id="video1" class="video-content">
                                            <div *ngIf="showvideo.length == 0" class="error_message_center">
                                                <h4 class="chaph3 error_message">Video(s) not available!
                                                    {{contentLoaded}}</h4>

                                            </div>
                                            <div [hidden]="!contentLoaded">
                                                <ngx-skeleton-loader
                                                    [theme]="{width: '200px','border-radius': '0',height: '15px','margin-bottom': '10px'}">
                                                </ngx-skeleton-loader>
                                            </div>
                                            <div class="videoWrapper" *ngIf="vimeoUrlLink; else elseblock">
                                                <!-- <div [innerHtml]="vimeoUrlLink || showvideo"></div> -->
                                                <iframe [src]="vimeoUrlLink | viemoUrl" width="256" height="144"
                                                    frameborder="0" webkitallowfullscreen mozallowfullscreen
                                                    allowfullscreen></iframe>
                                                <div [hidden]="!vimeoUrlLink || !showvideo">
                                                    <ngx-skeleton-loader
                                                        [theme]="{width: '200px','border-radius': '0',height: '15px','margin-bottom': '10px'}">
                                                    </ngx-skeleton-loader>
                                                </div>
                                            </div>
                                            <ng-template #elseblock>
                                                <div class="videoWrapper">
                                                    <!-- <div [innerHtml]="vimeoUrlLink || showvideo"></div> -->
                                                    <iframe [src]="showvideo | viemoUrl" width="256" height="144"
                                                        frameborder="0" webkitallowfullscreen mozallowfullscreen
                                                        allowfullscreen></iframe>
                                                    <div [hidden]="!vimeoUrlLink || !showvideo">
                                                        <ngx-skeleton-loader
                                                            [theme]="{width: '200px','border-radius': '0',height: '15px','margin-bottom': '10px'}">
                                                        </ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                            </ng-template>

                                            <h3 class="video-title">{{videotitle || onloadideotitle}}</h3>
                                        </div>


                                    </div>
                                    <div class="thumbnail-video">
                                        <div class="thumbnail-video-scroll">
                                            <ul id="videos-nav" class="videos-nav-loader" [hidden]="!contentLoaded">
                                                <li>
                                                    <a href="javascript:void(0)">
                                                        <div class="video-box">
                                                            <div>
                                                                <ngx-skeleton-loader
                                                                    [theme]="{width: '200px','border-radius': '0',height: '15px','margin-bottom': '10px'}">
                                                                </ngx-skeleton-loader>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <h5 class="video-title">
                                                                <ngx-skeleton-loader
                                                                    [theme]="{width: '200px','border-radius': '0',height: '15px','margin-bottom': '10px'}">
                                                                </ngx-skeleton-loader>
                                                            </h5>
                                                            <h6 class="sub-title">
                                                                <ngx-skeleton-loader
                                                                    [theme]="{width: '200px','border-radius': '0',height: '15px','margin-bottom': '10px'}">
                                                                </ngx-skeleton-loader>
                                                            </h6>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                            <ul id="videos-nav">
                                                <li class="active"
                                                    *ngFor="let url of chapterDetails.videos; let i=index"
                                                    [class.active]="selectedvideoIndex === i"
                                                    (click)="setIndex(i, 'videolist')">
                                                    <a href="javascript:void(0)" (click)="selectUrls(url)">
                                                        <div class="video-box">
                                                            <iframe [src]="url.videoLink | viemoUrl" width="256"
                                                                height="144" frameborder="0" webkitallowfullscreen
                                                                mozallowfullscreen allowfullscreen></iframe>
                                                        </div>

                                                        <div>
                                                            <h5 class="video-title"
                                                                style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                                                                title="{{url.videoTitle}}">
                                                                {{url.videoTitle}}
                                                            </h5>
                                                            <h6 class="sub-title"
                                                                style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                                                {{url.videoInstructor}}</h6>


                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="section-tabs">
                                <div class="tabs">
                                    <ul id="tabs-nav">
                                        <li class="active" [ngClass]="{ 'active':activeTab==='tab1'}"
                                            (click)="selsectTab('tab1')">
                                            <a>Course details</a>
                                        </li>
                                        <li [ngClass]="{ 'active':activeTab==='tab2'}" (click)="selsectTab('tab2')">
                                            <a>Assessment </a>
                                        </li>
                                        <li [ngClass]="{ 'active':activeTab==='tab3'}" (click)="selsectTab('tab3')">
                                            <a>Resources</a>
                                        </li>

                                    </ul> <!-- END tabs-nav -->

                                    <div id="tabs-content">
                                        <div *ngIf="tab1=='tab1'" class="tab-content">

                                            <div class="content-tab " [innerHtml]="chapterDetails?.description">
                                            </div>
                                        </div>
                                        <div *ngIf="tab2=='tab2'" class="tab-content">
                                            <div class="tab-assessment">
                                                <div class="section-file">
                                                    <div class="row-file">
                                                        <div class="left-section">

                                                            <h3 class="score">Score :
                                                                {{chapterDetails?.totalCorrectAnwser || '0'}} /
                                                                {{chapterDetails?.totalQuestion || '0'}}</h3>
                                                            <span class="completed"
                                                                *ngIf="chapterDetails?.assessmentStatus == 'review'">Completed</span>
                                                            <span class="progress-in"
                                                                *ngIf="chapterDetails?.assessmentStatus == 'continue'">In-progress</span>
                                                            <span class="progress-in"
                                                                *ngIf="chapterDetails?.assessmentStatus == 'continue' && chapterDetails?.assessmentStatus == 'review'">Pending</span>
                                                        </div>
                                                        <div class="right-section">
                                                            <h6 class="date">{{(chapterDetails?.submittedDate | date:
                                                                'mediumDate') || ''}}</h6>
                                                        </div>
                                                    </div>
                                                    <div class="row-file">
                                                        <!-- <div class="left-section">
                                                            <h3 class="score">Score :0 / 100</h3>
                                                            <span class="progress-in">In-progress</span>
                                                        </div> -->
                                                        <!-- <div class="right-section">
                                                            <h6 class="date">{{(chapterDetails?.submittedDate | date: 'mediumDate') || 'N/A'}}</h6>
                                                        </div> -->
                                                    </div>
                                                </div>
                                                <a *ngIf="chapterDetails?.assessmentStatus == 'take'" class="btn"
                                                    [routerLink]="['/dashboard/Assessment']"
                                                    [queryParams]="{chapterid: chapterDetails?._id,assignCourseId:assignCourseId}">Take
                                                    Assessment</a>
                                                <a *ngIf="chapterDetails?.assessmentStatus == 'continue'" class="btn"
                                                    [routerLink]="['/dashboard/Assessment']"
                                                    [queryParams]="{chapterid: chapterDetails?._id,assignCourseId:assignCourseId}">Continue
                                                    Assessment</a>
                                                <a class="btn" [routerLink]="['/dashboard/Assessment']"
                                                    [queryParams]="{chapterid: chapterDetails?._id,assignCourseId:assignCourseId}"
                                                    *ngIf="chapterDetails?.assessmentStatus == 'review'">Review
                                                    Assessment</a>
                                            </div>
                                        </div>
                                        <div *ngIf="tab3=='tab3'" class="tab-content">
                                            <div class="section-file">
                                                <div class="row-file" *ngFor="let sourse of chapterDetails?.resources">

                                                    <div class="left-section"><img src="assets/images/file-icon.png">
                                                        <a href="{{sourse.file}}" target="_blank">
                                                            <h3 class="file-name">

                                                                {{sourse?.title || 'N/A'}}
                                                            </h3>
                                                        </a>

                                                    </div>

                                                    <div class="right-section"><a class="btn" href="javascript:void(0)"
                                                            type="application/pdf"
                                                            (click)="ShowPdf(sourse.file)">View</a>

                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div> <!-- END tabs-content -->
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="popup" data-popup="popup-1" style="display: block;" *ngIf="ShowPdfModal">
    <div class="popup-inner score-inner">
        <h3>ViewPdf</h3>
        {{trustedUrl}}
        <a href="{{trustedUrl}}" target="_blank">aa</a>
        <a class="popup-close" data-popup-close="popup-1" href="javascript:void(0)" (click)="HidePdfemodal()"><img
                src="assets/images/popup-close.png"></a>
    </div>
</div> -->