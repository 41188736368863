import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Api_Service/api.service';
import { CommonService } from 'src/app/Common_Service/common.service';
import { endpoint } from 'src/app/Constants/endpoints';
import { router } from 'src/app/Constants/Router';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
  editProfileForm: FormGroup;
  value: any;
  url: any;
  formData: FormData;
  profileImage: any;
  profile: any;
  mycourseDetails: any;
  showprofile: boolean = true;
  showTermsCondition: boolean;
  showPrivacyPolicy: boolean;
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private commonService: CommonService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.editProfileForm = this.fb.group({
      "name": ['', Validators.compose([Validators.required])],
      "email": ['', Validators.compose([Validators.required])],

    })
    this.getProfile()
  }

  //  Get profile Api function  **  //

  getProfile() {
    this.apiService.getApi(endpoint.profile, 1).subscribe(res => {
      if (res.body.code || res.responseCode == 200) {
        // this.commonService.successToaster(res.body.message)
        this.commonService.dismissSpinner();
        this.profile = res.body.userdata
        this.editProfileForm.patchValue({ // PatchValue 
          "name": this.profile.name,
          "email": this.profile.email
        })
      }
    }, (error) => {
      if (error.error.code == 401) {
        // this.commonService.errorToast(error.error.message)
        this.commonService.logOut();
      }
      else {
        this.commonService.errorToast(error.statusText)
      }
    })

  }



  // SUploaf image event in form data
  onSelectFile(event) {
    this.value = event
    if (this.value.target.files && this.value.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(this.value.target.files[0]); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = event.target.result;
        console.log('url', this.url)
        this.uploadImage()
      }
    }
  }

  uploadImage() {
    var self = this;
    this.formData = new FormData();
    if (this.value.target.files && this.value.target.files[0]) {
      var type = this.value.target.files[0].type;
      var size = this.value.target.files[0].size;
      if (size < 2000000) {
        if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg' || type === 'image/gif' || type === 'image/tiff') {
          this.profileImage = this.value.target.files[0];
        } else {
          this.commonService.errorToast('This type is not acceptable!')
          this.url = '';
          return;
        }
      }
      else {
        this.commonService.errorToast('Size should be less than 2 MB.')
      }
      this.formData.append('profileImage', this.profileImage);
      this.apiService.postApi(endpoint.editProfile, this.formData, 3).subscribe(res => {
        if (res.code || res.responseCode == 200) {
          this.commonService.successToaster(res.message)
          this.commonService.dismissSpinner();
          this.getProfile()
        }
      }, (error) => {
        if (error.error.code == 401) {
          this.commonService.logOut();
          this.commonService.errorToast(error.error.message)

        }
      })
    }
  }


  //  Logout Function **//
  logOutProfile() {
    this.commonService.logOut()
    // this.commonService.presentSpinner();
    // this.commonService.errorToast('LogOut successfully.')
    // this.router.navigate([router.dashboard])
  }

  TabOpen(item) {
    if (item == 'Termscondition') {
      this.showprofile = false;
      this.showTermsCondition = true;
      this.showPrivacyPolicy = false;
    } else if (item == 'privacypolicy') {
      this.showprofile = false;
      this.showTermsCondition = false;
      this.showPrivacyPolicy = true;
    } else {
      this.showprofile = true;
      this.showTermsCondition = false;
      this.showPrivacyPolicy = false;
    }
  }

}
