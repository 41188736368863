<div id="wrapper">
  <div class="inner-banner" style="background-image: url(assets/images/Image_61.png);">
    <div class="container">
      <!-- <h1 class="page-title">Access Institute of Ultrasound Training Courses</h1> -->
    </div>
  </div>
  <div class="content">
    <div class="container">
      <div class="form-login form-forgot-password">
        <h2 class="form-title"> Forgot Password </h2>
        <p>Please enter your email then we will help you create a new password</p>
        <form [formGroup]="emailForm">
          <div class="input">
            <div class=" input_field input_field_full">
              <div class="input-icon"><img src="assets/images/email-icon.png"></div>
              <input type="email" id="email" (keydown.space)="$event.preventDefault()" formControlName="email"
                name="email" placeholder="Email" required>
            </div>
            <div class="fonts error-message"
              *ngIf="emailForm.get('email').hasError('maxlength') && emailForm.get('email').dirty">
              Maxlength of characters should be 64.
            </div>
            <div
              *ngIf="emailForm.get('email').errors && (emailForm.get('email').touched || emailForm.get('email').dirty)">
              <div *ngIf="emailForm.controls['email'].hasError('required')"><span class="fonts error-message">Mail
                  id is required.</span></div>
              <div *ngIf="emailForm.controls['email'].hasError('pattern')"><span class="fonts error-message">Please
                  eneter valid
                  mail id.</span>
              </div>
            </div>
          </div>
          <div class="input button-submit"><input name="button" type="submit" value="Next" (click)="sendLink()"
              [disabled]="!emailForm.valid" id="submit"></div>
        </form>
      </div>
    </div>
  </div>
</div>