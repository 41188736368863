import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { EmbedVideoService } from 'ngx-embed-video';
import { ApiService } from 'src/app/Api_Service/api.service';
import { CommonService } from 'src/app/Common_Service/common.service';
import { endpoint } from 'src/app/Constants/endpoints';
// import * as $ from "jquery"
declare var $: any;

@Component({
  selector: 'app-chapters',
  templateUrl: './chapters.component.html',
  styleUrls: ['./chapters.component.css']
})
export class ChaptersComponent implements OnInit {
  @ViewChildren('ytp-chrome-top-buttons') private _notificationsElements: QueryList<ElementRef>;

  // chepter_id="/5fd87e00ccabf51e30304817";
  active: string;
  tab2: any;
  tab1: any;
  tab3: any;
  chapterList: any = [];
  chepter_id = '';
  chapterDetails: any = [];
  vimeo_iframe_html: any;
  vimeoUrl: any = [];
  selectUrl: any;
  safeSrc: any = [];
  embedUrl: any = [];
  vimeoUrlLink: any;
  value = false;
  selectedIndex: number = 0;
  selectedvideoIndex: number = 0;
  contentLoaded: boolean = false;
  activeTab = 'tab1';
  assignCourseId: any;

  constructor(private ApiService: ApiService,
    private activateRouter: ActivatedRoute,
    private commonService: CommonService,
    private router: Router,
    private embedService: EmbedVideoService,
    private sanitizer: DomSanitizer,


  ) {
    this.activateRouter.queryParams.subscribe(params => {
      this.chepter_id = params.courseid
      this.assignCourseId = params.assigncourseid

    });
    this.tab1 = "tab1"


  }
  setIndex(index: number, keydata: string) {
    if (keydata == 'chapterlist') {
      this.selectedIndex = index;

    } else {
      this.selectedvideoIndex = index;
    }
  }

  ngOnInit(): void {
    // this.getMyCourse() 
    this.getChapterList();

  }

  // Get chapter List **//
  firstvideoid: any;
  getChapterList() {
    this.contentLoaded = true;
    this.ApiService.getApi(endpoint.chapter + this.chepter_id, 1).subscribe(res => {
      console.log('res', res)

      if (res.body.code || res.responseCode == 200) {
        setTimeout(() => {
          this.contentLoaded = false;

        }, 5000);
        this.chapterList = res.body.chapterList;
        this.firstvideoid = this.chapterList[0]._id;
        this.chaperDetails(this.firstvideoid);
      }
    }, (error) => {
      if (error.error.code == 401) {
        setTimeout(() => {
          this.contentLoaded = false;

        }, 200);
        // this.commonService.errorToast(error.error.message)
        this.commonService.logOut();
      } else if (error.error.code === 400) {
        this.router.navigate(['dashboard/my-course']);
        // this.commonService.dismissSpinner();
        // this.commonService.errorToast('Chapter list not available.')
      }
      else {
        this.commonService.errorToast(error.statusText)
      }
    })
  }

  //  Selectchapter details ** //   
  showvideo: any = [];
  onloadideotitle: any;
  myElements: any;
  resourceurl: any = [];
  resUrl: any = [];
  chaperDetails(_id) {
    // document.getElementsByClassName('.ytp-chrome-top-buttons'). =''

    // this.myElements.style.display = 'none'

    this.contentLoaded = true;
    this.vimeoUrl = [];
    this.showvideo = [];
    var obj = {};
    this.vimeoUrlLink = '';
    this.onloadideotitle = '';

    this.videotitle = '';
    this.ApiService.getApi(endpoint.chapterDetails + _id + '?assignCourseId=' + this.assignCourseId, 1).subscribe(res => {
      if (res.body.code || res.responseCode == 200) {
        this.commonService.dismissSpinner();
        this.value = true;
        // this.commonService.successToaster(res.body.message)
        this.chapterDetails = res.body.chapterData;
        this.contentLoaded = false;
        for (var i = 0; i < this.chapterDetails.videos.length; i++) {
          // this.safeSrc = this.embedService.embed(this.chapterDetails.videos[i].videoLink, { query: { 'rel': 0 }, attr: { allow: 'accelerometer,accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' } });
          var embedurl = this.chapterDetails.videos[i].videoLink.split('/');
          if (embedurl.length == 4) {
            // var popurl = embedurl.pop();
            this.vimeoUrl.push("https://vimeo.com/" + embedurl[3] + "?loop=1")
          } else if (embedurl.length == 5) {
            this.vimeoUrl.push("https://vimeo.com/" + embedurl[3] + '?h=' + embedurl[4])
          }
          else {
            var embedurl = this.chapterDetails.videos[i].videoLink;
            this.vimeoUrl.push(embedurl + "?loop=1")
          }

          this.chapterDetails.videos[i]['videoLink'] = this.vimeoUrl[i];
          this.showvideo = this.chapterDetails.videos[0].videoLink;
        }

        console.log(this.chapterDetails)

      }

    }, (error) => {
      if (error.error.code == 401) {
        // this.commonService.errorToast(error.error.message)
        this.commonService.logOut();
      }
      else {
        this.commonService.errorToast(error.statusText)
      }
    })
    this.vimeoUrl = [];

  }


  videotitle: any;
  selectUrls(url) {

    this.vimeoUrlLink = url.videoLink ? url.videoLink : '';
    this.videotitle = url.videoTitle;
  }

  selsectTab(val) {
    console.log('val', val)
    if (val == 'tab1') {
      this.tab2 = '';
      this.tab3 = '';
      this.tab1 = "tab1";
      this.activeTab = "tab1";
    } else if (val == "tab2") {
      this.tab1 = '';
      this.tab3 = '';
      this.tab2 = "tab2";
      this.activeTab = "tab2";
    } else if (val == "tab3") {
      this.tab1 = '';
      this.tab2 = '';
      this.tab3 = "tab3"
      this.activeTab = "tab3";
    }
  }
  ShowPdfModal: boolean;
  showpdf: any;
  urlSafe: SafeResourceUrl;
  trustedUrl: any;
  ShowPdf(resourceUrl) {

    this.ShowPdfModal = true;
    // this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(resourceUrl.file);
    this.trustedUrl = 'https://docs.google.com/gview?url=' + resourceUrl + '&embedded=true';
    window.open(this.trustedUrl, "_blank")
  }

  HidePdfemodal() {
    this.ShowPdfModal = false;
  }
}
