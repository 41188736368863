<header class="header" [ngClass]="{'mat-elevation-z5' : true, 'sticky' : isSticky}" *ngIf="generic.isLogin == false">
    <div class="container">
        <div class="header-left">
            <div class="logo"><a [routerLink]="['/']"><img src="assets/images/logo.png" alt="logo"></a>
            </div>
        </div>
        <div class="header-right">
            <!-- <a class="login_button" (click)="goto()">Login</a> -->

        </div>
    </div>
</header>

<header class="header " [ngClass]="{'mat-elevation-z5' : true, 'sticky' : isSticky}" *ngIf="generic.isLogin == true">
    <div class="container">
        <div class="header-left">
            <div class="logo"><a [routerLink]="['/dashboard/my-course']"><img src="assets/images/logo.png"
                        alt="logo"></a>
            </div>
        </div>

        <div class="header-right">
            <div class="user-menu">
                <span class="user-info">
                    <span class="user-img"
                        style="background-image: url({{generic.userInfo.profileImage}}); background-size: cover; background-repeat: no-repeat; background-position: center;"></span>
                    <span class="info-user">
                        <h5 class="user-name">{{generic.userInfo.name}}</h5>
                        <h6 class="user-mail">{{generic.userInfo.email}}</h6>
                    </span>
                </span>

                <ul class="menu-dropdown">
                    <li><a href="javascrpt:void(0)" [routerLink]="['/dashboard/my-profile']">Profile</a></li>
                    <li><a href="javascript:void(0);" (click)="lgout()">Logout</a></li>
                </ul>
            </div>
        </div>
    </div>
    <!-- <span class="info_user prof">
        <span (click)="lgout()">
            <img src="{{generic.userInfo.profileImage}}" class="user-imgs" width="120" height="120">
        </span>
        <span>

            <h5 class="user-name">{{generic.userInfo.name}}</h5>
            <h6 class="user-mail">{{generic.userInfo.email}}</h6>
        </span>
    </span> -->
</header>
<div *ngIf="generic.isLogin == true">
    <div class="popup" data-popup="popup-1" style="display: block;" *ngIf="ShowModal">
        <div class="popup-inner score-inner">
            <p>You are currently logged in using this ID {{generic.userInfo.email}}</p>
            <a class="btn" href="javascript:void(0)" (click)="Hidemodal()">Done</a>
            <a class="popup-close" data-popup-close="popup-1" href="javascript:void(0)" (click)="Hidemodal()"><img
                    src="assets/images/popup-close.png"></a>
        </div>
    </div>
</div>




<ngx-spinner size="large" color="#fa9c2e" type="triangle-skew-spin">
    <p style="color: white">Please wait page is loading... </p>
</ngx-spinner>
<router-outlet></router-outlet>

<footer id="footer">
    <div class="container">
        <div class="fooetr-top">
            <div class="row">
                <div class="col60">
                    <div class="number-bar"><span>Contact us for any query :</span><a class="numberone"
                            href="tel:995 838 7900">995 838 7900,</a><a class="numbertwo"
                            href="tel:011-255 986 80">011-255 986
                            80</a></div>
                </div>
                <div class="col40">
                    <div class="mail-bar"><span><img src="assets/images/mail-icon.png"></span><a class="mailone"
                            href="mailto:iut364@yahoo.com">iout364@yahoo.com</a><a class="mailtwo"
                            href="mailto:aashi@ultrasound.net.in">aashi@ultrasound.net.in</a></div>
                </div>
            </div>
        </div>

        <div class="fooetr-bottom">
            <div class="row">
                <div class="col40">
                    <nav class="menu-footer">
                        <ul>
                            <li><a [routerLink]="['terms-conditions']">Terms & Conditions</a></li>
                            <li><a [routerLink]="['privacy-policy']">Privacy Policy</a></li>
                            <!-- <li><a href="#">Affiliations</a></li>
                            <li><a href="#">Virtual tour</a></li>  
                            <li><a href="#">Courses</a></li>
                            <li><a href="#">Contact</a></li> -->
                        </ul>
                    </nav>
                </div>
                <div class="col60">
                    <div class="social-icons">
                        <ul>
                            <li><a href="https://www.facebook.com/DrRandhawa.Ultrasoundtraining" target="_blank"><i
                                        class="fa fa-facebook" aria-hidden="true"></i></a></li>
                            <li><a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                            <li><a href="javascript:void(0)"><i class="fa fa-youtube-play" aria-hidden="true"></i></a>
                            </li>
                            <li><a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                        </ul>
                    </div>
                    <div class="copyright">2016 © Institute of Ultrasound Technology All Rights Reserved. Powered by <a
                            href="https://greychaindesign.com/" target="_blank" style="color:#ffffff8f;">Grey Chain</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>