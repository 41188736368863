import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './Login_Fllow_Components/reset-password/reset-password.component';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import { EmbedVideo } from 'ngx-embed-video';

// Components ************************************************//
import { DashboardComponent } from './Login_Fllow_Components/dashboard/dashboard.component';
import { LoginComponent } from './Login_Fllow_Components/login/login.component';
import { MyCourseComponent } from './AfterLogin_Components/my-course/my-course.component';
import { ForgotComponent } from './Login_Fllow_Components/forgot/forgot.component';
import { ChangePasswordComponent } from './AfterLogin_Components/change-password/change-password.component';
import { MyProfileComponent } from './AfterLogin_Components/my-profile/my-profile.component';
import { ChaptersComponent } from './AfterLogin_Components/chapters/chapters.component';
import { PageNotFoundComponent } from './AfterLogin_Components/page-not-found/page-not-found.component';
import { AssessmentComponent } from './AfterLogin_Components/assessment/assessment.component';
import { HeaderComponent } from './header/header.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ViemoUrlPipe } from './AfterLogin_Components/chapters/viemo-url.pipe';


@NgModule({
  declarations: [
    AppComponent,
    ResetPasswordComponent,
    LoginComponent,
    DashboardComponent,
    MyCourseComponent,
    ForgotComponent,
    ChangePasswordComponent,
    MyProfileComponent,
    ChaptersComponent,
    PageNotFoundComponent,
    AssessmentComponent,
    HeaderComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    ViemoUrlPipe,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxSkeletonLoaderModule,
    EmbedVideo,
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
    }),
    BrowserAnimationsModule,
    NgxSpinnerModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [],
})
export class AppModule { }
