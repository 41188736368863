import { identifierModuleUrl } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Api_Service/api.service';
import { CommonService } from 'src/app/Common_Service/common.service';
import { endpoint } from 'src/app/Constants/endpoints';
import { regex } from 'src/app/Constants/regex';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {

  emailForm: FormGroup;
  constructor(private fb: FormBuilder,
    private apiService: ApiService, // Api service call in constructor for api methods ** //
    private commonService: CommonService, // Commonservice call in constructor for toaster and apinner **// 
    private router: Router) { }

  ngOnInit(): void {
    this.emailForm = this.fb.group({
      "email": ['', Validators.compose([Validators.required, Validators.maxLength(64), Validators.pattern(regex.email)])],
    })
  }

  // Send link on the registered mail **  //
  sendLink() {
    this.commonService.presentSpinner();
    let obje = {
      "email": this.emailForm.value.email,
      "resetUrl": this.apiService.baseUrl,
    }
    this.apiService.postApi(endpoint.forgotPassword, obje, 0).subscribe(res => {
      console.log('res', res)
      if (res.body.code || res.responseCode == 200) {
        this.commonService.dismissSpinner();
        this.commonService.successToaster(res.body.message)
        console.log('urlreset',localStorage.setItem('url',res.body.resetUrl))
        this.commonService.dismissSpinner();
        // this.router.navigate(['/dashboard/login'])
      }
    }, (error) => {
      if (error.error.message) {
        this.commonService.errorToast(error.error.message)
      } else {
        this.commonService.errorToast(error.statusText)
      }
    })
  }


}
