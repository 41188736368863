import { Component, HostListener, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EventEmitter } from 'events';
import { ApiService } from 'src/app/Api_Service/api.service';
import { GenericService } from 'src/app/Api_Service/generic.service';
import { CommonService } from 'src/app/Common_Service/common.service';
import { endpoint } from 'src/app/Constants/endpoints';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  loginStatus: boolean = false;
  loginStatus1: boolean = false;
  profile: any;
  aaa: any;
  isSticky: boolean;
  ShowModal: boolean;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 86;
  }
  constructor(
    private apiService: ApiService,
    private commonService: CommonService,
    public generic: GenericService,
    private router: Router,

  ) {

  }

  ngOnInit(): void {
    // setInterval(() => {
    //   this.ShowModal = true;
    // }, 5000);
    this.generic;
    this.checkToken();
    this.getProfile();

  }
  goto() {
    if (document.getElementById('wrapper')) {
      document.getElementById('wrapper').scrollIntoView()

    }
  }


  // Check Token ** //
  checkToken() {
    if (localStorage.getItem('token')) {
      this.loginStatus = true;
    } else {
      this.loginStatus = false;
    }
  }


  getProfile() {
    this.loginStatus = false;

    if (localStorage.getItem('token')) {
      this.loginStatus = false;

      this.apiService.getApi(endpoint.profile, 1).subscribe(res => {
        if (res.body.code || res.responseCode == 200) {
          this.profile = res.body.userdata
          setInterval(() => {
            this.ShowModal = true;
          }, 600000);
          this.checkToken()
          console.log('profile', this.profile)

        }
      }, (error) => {
        if (error.error.code == 401) {
          this.commonService.logOut();
          // this.commonService.errorToast(error.error.message)

        } else {
          this.commonService.logOut();
          this.commonService.errorToast(error.message)
        }
      })
    }
  }

  lgout() {

    let obj = {};
    this.apiService.postApi(endpoint.logout, obj, 1).subscribe(res => {
      if (res.body.code || res.responseCode == 200) {
        this.commonService.successToaster(res.body.message)
        this.generic.isLogin = false;
        localStorage.clear();
        this.router.navigate(['/dashboard/login'])
        // location.reload();

        // this.commonService.logOut();
        // this.checkToken();

      }
    }, (error) => {
      if (error.error.code == 401) {
        this.generic.isLogin = false;
        location.reload();
        localStorage.clear();
      }

    })



  }

  Hidemodal() {
    this.ShowModal = false;
  }
}
