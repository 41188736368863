<div id="wrapper">
    <div class="content">
        <div class="section-profile">
            <div class="container">
                <form class="row" [formGroup]="editProfileForm">
                    <div class="col25">
                        <div class="profile-left">
                            <div class="profile-box">
                                <div class="user_info">
                                    <div class="user-profile">
                                        <!-- [src]="url ? url : 'https://www.w3schools.com/howto/img_avatar.png'" -->
                                        <!-- <img [src]="url ? url : profile?.profileImage"
                                            class="user-img" width="120" height="120"> -->
                                        <span class="user-img"
                                            [ngStyle]="{'background-image':'url(' + (url ? url : profile?.profileImage) + ')'}"
                                            style="    background-color: rgb(245, 245, 245); background-size: cover  !important; background-repeat: no-repeat  !important; background-position: center  !important;"></span>
                                        <span class="profile_upload"> <input type="file" id="img"
                                                accept="image/jpg,image/png,image/jpeg,image/gif,image/tiff"
                                                (change)="onSelectFile($event)" class="upload-button" name="img"
                                                accept="image/*">
                                            <img src="assets/images/upload-button.png" alt="Upload Button"
                                                class="upload-icon">
                                        </span>
                                    </div>
                                    <span class="info_user">
                                        <h5 class="user-name">{{profile?.name || 'N/A'}}</h5>
                                        <h6 class="user-mail">{{profile?.email || 'N/A'}}</h6>
                                    </span>
                                </div>
                            </div>
                            <div class="profile-nav">
                                <ul>
                                    <li class="active"><a href="javascript:void(0)" (click)="TabOpen('myprofile')">My
                                            Profile</a></li>
                                    <!-- <li><a href="javascript:void(0);" (click)="TabOpen('Termscondition')">Terms
                                            &
                                            Conditions</a>
                                    <li><a href="javascript:void(0);" (click)="TabOpen('privacypolicy')">Privacy
                                            Policy</a>
                                    </li> -->
                                    <li><a href="javascript:void(0)" (click)="logOutProfile()">Logout</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col75" *ngIf="showprofile">
                        <div class="profile-right">
                            <a href="javascript:void(0)" class="back-btn"
                                [routerLink]="['/dashboard/my-course']">Back</a>
                            <h2 class="section-title">Profile</h2>
                            <div class="profile-form">
                                <div class="row">
                                    <div class="col50">
                                        <div class="input">
                                            <label>Name</label>
                                            <input type="name" id="name" formControlName="name" name="name" readonly>
                                        </div>
                                    </div>
                                    <div class="col50">
                                        <div class="input">
                                            <label>Email</label>
                                            <input type="email" id="email" formControlName="email" name="email"
                                                readonly>
                                        </div>
                                    </div>
                                    <div class="col50">
                                        <div class="input">
                                            <label>Password</label>
                                            <input type="password" id="password" name="password"
                                                placeholder="***********" readonly>
                                            <a [routerLink]="['/dashboard/change-password']"
                                                class="password-change">Change</a>
                                        </div>
                                    </div>
                                    <div class="col100">
                                        <div class="input button-submit"><input name="button" type="submit" value="Save"
                                                id="submit"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col75" *ngIf="showTermsCondition">
                        <div class="profile-right profile_content content-tab">
                            <a href="javascript:void(0)" class="back-btn"
                                [routerLink]="['/dashboard/my-course']">Back</a>
                            <h2 class="section-title">Terms & Condition</h2>
                            <p>This Website is created and operated by <strong>Institute of Ultrasound Training</strong>
                                having its
                                registered office at <strong>“511-12, Westend Mall, Janakpuri, New Delhi-110058
                                    India”</strong>
                                represented by its Directors, hereinafter referred to as the <strong>“Company”</strong>
                                (where such
                                expression shall, unless repugnant to the context thereof, be deemed to include its
                                respective
                                legal heirs, representatives, administrators, permitted successors and assigns).</p>
                            <p>
                                This legal agreement is an electronic record in terms of Indian Information Technology
                                Act,
                                2000 and rules there under as applicable and the amended provisions about electronic
                                records in various statutes as amended by the Indian Information Technology Act, 2000.
                                This electronic record is generated by a computer system and does not require any
                                physical
                                or digital signatures.</p>
                            <p>
                                This legal document is published in accordance with the provisions of Rule 3 (1) of the
                                Indian Information
                                Technology (Intermediaries guidelines) Rules, 2011 and Rule 4 of the Information
                                Technology (Reasonable security
                                practices and procedures and sensitive personal data or information) Rules, 2011 of
                                Information Technology Act, 2000
                                amended through Information Technology Amendment Act, 2008 that require publishing the
                                Terms of Services and
                                practices for access or usage of <strong>“drrandhawainstitute.com”
                                    (“Website/platform”)</strong>

                            </p>
                            <p>The creator of these Terms of Service ensures your steady commitment to the usage of your
                                platform and the services
                                provided by the company on the Website <strong> “drrandhawainstitute.com”
                                </strong>(herein after referred to as the
                                <strong>“Platform”</strong>).</p>
                            <p>For the purpose of these Terms of Use <strong>(“Terms”)</strong>, wherever the context so
                                requires, “We”, “Our”, and
                                “Us” shall mean
                                and refer to the Platform and/or the Company, as the context so requires. “You”, “Your”,
                                “Yourself”, “User” shall
                                mean and refer to natural and legal individuals who shall be Users of this Platform
                                provided by the company and who
                                is competent to enter into binding contracts, as per Indian laws. “Third Parties” refer
                                to any Website, Platform or
                                individual apart from the Users and the creator of this platform. “Platform” refers to
                                the Website created by the
                                Company.</p>
                            <h3><strong>GENERAL TERMS</strong></h3>

                            <ul>
                                <li>The headings of each section in these Terms or Privacy Policy are only for the
                                    purpose of organizing the various
                                    provisions under these Terms and the Privacy Policy in an orderly manner and shall
                                    be used by either Party to
                                    interpret the provisions contained herein in a manner as may apply to the User.
                                    Further, it is specifically
                                    agreed to by the Parties that the headings shall have legal or contractual value on
                                    the User availing the
                                    services from the Platform.
                                </li>
                                <li> The use of this Platform by the Users is solely governed by these Terms, Privacy
                                    Policy and as well as other
                                    policies that may be uploaded on the platform the purpose of the Users effective
                                    applicability, and any
                                    modifications or amendments made thereto by the Company, from time to time, at its
                                    sole discretion. If you as a
                                    User continue to access and use this Platform, you are agreeing to comply with and
                                    be bound by the following
                                    Terms and Conditions, Privacy Policy and such other policy as that may be available
                                    and applicable on the Users
                                    of the Platform.
                                </li>
                                <li> The User expressly agrees and acknowledges that these Terms and Policy are
                                    co-terminus in nature and that
                                    expiry/termination of either one will lead to the termination of the other.
                                </li>
                                <li> The User unequivocally agrees that these Terms and the aforementioned Policies
                                    constitute a legally binding
                                    agreement between the User and the Company, and that the User shall be subject to
                                    the rules, guidelines,
                                    policies, terms, and conditions applicable to any service that is provided by the
                                    Platform, and that the same
                                    shall be deemed to be incorporated into these Terms, and shall be treated as part
                                    and parcel of the same. The
                                    User acknowledges and agrees that no signature or express act is required to make
                                    these Terms and the Policy
                                    binding on the User and that the User’s act of visiting any part of the Platform
                                    constitutes the User’s full and
                                    final acceptance of these Terms and the aforementioned Policy.
                                </li>
                                <li> The Company reserves the sole and exclusive right to amend or modify these Terms
                                    without any prior permission
                                    or intimation to the User, and the User expressly agrees that any such amendments or
                                    modifications shall come
                                    into effect immediately. If the User does not adhere to the changes, they must stop
                                    using the Services at once.
                                    Their continuous use of the Services will signify your acceptance of the changed
                                    terms.
                                </li>
                            </ul>
                            <h3><strong>PLATFORM OVERVIEW</strong></h3>
                            <p>
                                The Platform deals in providing teaching and training services to the students of
                                Institute of Ultrasound Training
                                based out of New Delhi, India. The services shall be provided through video links, notes
                                in the form of PDF and word
                                document. The Institute is solely dedicated to education, training and research in
                                Diagnostic ultrasound and Color
                                Doppler since 2000. The Institute of Ultrasound Training is an affiliate education
                                center of Jefferson Ultrasound
                                Radiology & Education Institute, Thomas Jefferson University Hospital, Philadelphia,
                                USA- the only collaborating
                                center in the world recognized by World Health Organization (WHO) for imparting training
                                & Education in diagnostic
                                ultrasound.

                            </p>
                            <h3><strong>REGISTRATION</strong></h3>
                            <p>Registration is mandatory for all users of the platform. The Registrations shall be
                                deemed complete only upon the
                                user's authentication and verification of the user's details through the mobile or email
                                authentication. You are
                                required to register Yourself on the Platform in order to avail the Platform and the
                                courses listed on Platform. For
                                the purpose registration, the User shall provide the following details on the platforms
                                in order to fill up the
                                registration form. The Registrations shall be deemed complete only upon the user's
                                authentication and verification
                                of the user's details through the mobile or email authentication.
                            </p>
                            <ul>
                                <li>
                                <li>Name</li>
                                <li>Email-ID</li>
                                <li>Mobile Number</li>
                            </ul>
                            <p>
                                Further, the Platform provides services to the User on subscription, wherein the company
                                provides access to users
                                from a backend. The users shall subscribe in order to use the Platform by using login
                                credentials and can further
                                access video courses and assessments related to medical practice listed on the Platform.
                            </p>
                            <h3><strong>ELIGIBILITY</strong></h3>
                            <ul>
                                <li>The Users jointly represents and warrants that they are competent and eligible to
                                    enter into legally binding
                                    agreements and of age and that they have the requisite authority to bind themselves
                                    to these Terms following the
                                    Law.
                                </li>
                                <li> The Users further represents that they will comply with this Agreement and all
                                    applicable local, state,
                                    national and international laws, rules and regulations.
                                </li>
                                <li> The Users may not use the Platform if they are not competent to contract or are
                                    disqualified from doing so by
                                    any other applicable law, rule or regulation currently in force.
                                </li>
                            </ul>
                            <h3><strong>CONTENT</strong></h3>
                            <p>All text, graphics, User interfaces, visual interfaces, photographs, trademarks, logos,
                                brand names, descriptions,
                                (collectively, ‘Content’), is generated/provided or based on information provided by the
                                users or third parties and
                                the Platform has no control and make no guarantees regarding the quality, the accuracy,
                                integrity or genuineness of
                                such content or such other information provided on the Platform.
                            </p>
                            <p>All the Content displayed on the Platform is subject to copyright and shall not be reused
                                by any party (or a third
                                party) without the prior written consent of the Company and the copyright owner.
                            </p>
                            <p>The Users are solely responsible for the integrity, authenticity, quality and genuineness
                                of the content provided on
                                the Platforms and whilst feedback and comments by Users can be made via the Platform,
                                the Platform bears no
                                liability whatsoever for any feedback or comments made by the Users or made in respect
                                of any of the content on the
                                Platform. Further, the Platform reserves its right to suspend the account of any User
                                for an indefinite period to be
                                decided at the discretion of the Platform or to terminate the account of any User who is
                                found to have created or
                                shared or submitted any Content or part thereof that is found to be
                                untrue/inaccurate/misleading or
                                offensive/vulgar. The User shall be solely responsible for making good any financial or
                                legal losses incurred
                                through the creation/sharing/submission of Content or part thereof that is deemed to be
                                untrue/inaccurate/misleading.
                            </p>
                            <p>The Users have a personal, non-exclusive, non-transferable, revocable, limited privilege
                                to access the
                                Content on the Platform. Users shall not copy, adapt, and modify any content without
                                written permission of the
                                Company.
                            </p>
                            <h3><strong>INDEMNITY</strong></h3>
                            <p>The Users of this Platform agree to indemnify, defend and hold harmless the
                                Company/Platform, and their respective
                                directors, officers, employees and agents (collectively, <strong>"Parties"</strong>),
                                from and against any losses,
                                liabilities, claims, damages, demands, costs and expenses (including legal fees and
                                disbursements in connection
                                therewith and interest chargeable thereon) asserted against or incurred by us that arise
                                out of, result from, or
                                maybe payable by, any breach or non-performance of any representation, warranty,
                                covenant or agreement made or
                                obligation to be performed according to these terms of use. Further, the User agrees to
                                hold the Company/Platform
                                harmless against any claims made by any third party due to, or arising out of, or in
                                connection with:
                            </p>
                            <ul>
                                <li>User’s use of the Platform, </li>
                                <li>User’s violation of these Terms and Conditions; </li>
                                <li>User’s violation of any rights of another;</li>
                                <li>User’s alleged improper conduct according to these Terms;</li>
                                <li>User’s conduct in connection with the Platform;</li>
                            </ul>
                            <p>
                                User agrees to fully cooperate in indemnifying the Company and the Platform at the
                                user’s expense. The user also
                                agrees not to settle with any party without the consent of the Company.
                            </p>
                            <p>In no event shall the Company be liable to compensate the User or any third party for any
                                special, incidental,
                                indirect, consequential or punitive damages whatsoever, including those resulting from
                                loss of use, data or profits,
                                whether or not foreseeable, and whether or not the Company/ Platform had been advised of
                                the possibility of such
                                damages, or based on any theory of liability, including breach of contract or warranty,
                                negligence or other tortuous
                                action, or any other claim arising out of or in connection with the User’s use of or
                                access to the Platform and/or
                                the Services or materials contained therein.
                            </p>
                            <h3><strong>LIMITATION OF LIABILITY</strong></h3>
                            <ul>
                                <li>
                                    The Founders/ Promoters/ Partners/ Associated people of the Company/Platform are not
                                    responsible for any
                                    consequences arising out of the following events:
                                    <ul>
                                        <li>
                                            If the Platform is inoperative/non-responsive due to any connectivity errors
                                            associated with the
                                            internet connection such as but not limited to slow connectivity, no
                                            connectivity, server failure;
                                        </li>
                                        <li>If the User has fed incorrect information or data or for any deletion of
                                            data;
                                        </li>
                                        <li>If there is an undue delay or inability to communicate through email;
                                        </li>
                                        <li>If there is any deficiency or defect in the Services managed by Us;
                                        </li>
                                        <li>If there is a failure in the functioning of any other service provided by
                                            the Platform.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    The Platform accepts no liability for any errors or omissions, on behalf of itself,
                                    or for any damage caused to
                                    the User, the User’s belongings, or to any third party, resulting from the use or
                                    misuse of the Platform or any
                                    service availed of by the User through the Platform. The service and any Content or
                                    material displayed on the
                                    service is provided without any guarantees, conditions or warranties as to its
                                    accuracy, suitability,
                                    completeness or reliability. The Platform will not be liable to you for the
                                    unavailability or failure of the
                                    Platform.
                                </li>
                                <li>Users are to comply with all laws applicable to them or their activities, and with
                                    all Policies, which are
                                    hereby incorporated into this Agreement by reference.
                                </li>
                                <li>The Platform expressly excludes any liability for any loss or damage that was not
                                    reasonably foreseeable by the
                                    Platform and which is incurred by you in connection with the Platform, including
                                    loss of profits; and any loss
                                    or damage incurred by you as a result of your breach of these terms.

                                </li>
                            </ul>
                            <p>To the fullest extent permitted by law, the Platform shall not be liable to you or any
                                other party for any loss or
                                damage, regardless of the form of action or basis of any claim. You acknowledge and
                                agree that your sole and
                                exclusive remedy for any dispute with us is to terminate your use of the Platform.
                            </p>
                            <h3><strong>TERM</strong></h3>
                            <ul>
                                <li>These Terms shall continue to form a valid and binding contract between the Parties
                                    and shall continue to be in
                                    full force and effect until the User continues to access and use the Platforms.
                                </li>
                                <li>The Users may terminate their use of the Platform at any time.
                                </li>
                                <li>The Company may terminate these Terms and close any account at any time without
                                    notice and/or suspend or
                                    terminate a User’s access to the Platform at any time and for any reason, if any
                                    discrepancy or legal issue
                                    arises.
                                </li>
                                <li>Such suspension or termination shall not limit our right to take any other action
                                    against you that the Company
                                    considers appropriate.
                                </li>
                                <li>It is also hereby declared that the Company may discontinue the Services and
                                    Platforms without any prior notice.
                                </li>
                            </ul>
                            <h3><strong>TERMINATION</strong></h3>
                            <ul>
                                <li>The Company reserves the right, in its sole discretion, to unilaterally terminate
                                    the User’s access to the
                                    Platform, or any portion thereof, at any time, without notice or cause.
                                </li>
                                <li> The Platform also reserves the universal right to deny access to particular users,
                                    to any/all of are on its
                                    Platform without any prior notice/explanation to protect the interests of the
                                    Platform and/or other visitors to
                                    the Platform.
                                </li>
                                <li> The Platform reserves the right to limit, deny or create different access to the
                                    Platform and its features
                                    concerning different Users, or to change any of the features or introduce new
                                    features without prior notice.
                                </li>
                                <li> The User shall continue to be bound by these Terms, and it is expressly agreed to
                                    by the Parties that the User
                                    shall not have the right to terminate these Terms till the expiry of the same.
                                </li>
                            </ul>
                            <h3><strong>COMMUNICATION</strong></h3>
                            <p>By using this Platform and providing his/her identity and contact information to the
                                Company through the Platform,
                                the Users hereby agree and consent to receive calls, e-mails or SMS from the Company
                                and/or any of its
                                representatives at any time. (only with users consent)
                            </p>
                            <p> Users can report to “iout364@yahoo.com” if they find any discrepancy with regard to
                                Platform or content-related
                                information and the Company will take necessary action after an investigation. The
                                response with resolution (if any
                                issues found) shall be dependent on the time is taken for investigation.
                            </p>
                            <p>The User expressly agrees that notwithstanding anything contained hereinabove, it may be
                                contacted by the Company or
                                any representatives relating to any services availed by the User on the Platform or
                                anything pursuant thereto and
                                the Users agrees to indemnify the Company from any harassment claims. It is expressly
                                agreed to by the Parties that
                                any information shared by the User with the Company shall be governed by the Privacy
                                Policy.
                            </p>
                            <h3><strong>USER OBLIGATIONS AND FORMAL UNDERTAKINGS AS TO CONDUCT</strong></h3>
                            <p> The User agrees and acknowledges that they are a restricted user of this Platform and
                                that they:
                            </p>
                            <ul>
                                <li>Agree to ensure the Name, Email address, Address, Mobile number and any such other
                                    information is valid at all
                                    times and shall keep your information accurate and up-to-date.
                                </li>
                                <li>Users agree that they are solely responsible for maintaining the confidentiality of
                                    your account password. You
                                    agree to notify us immediately of any unauthorized use of your account. Company
                                    reserves the right to close your
                                    account at any time for any or no reason.
                                </li>
                                <li>Understand and acknowledge that the data submitted is manually entered into the
                                    database of the Platform. The
                                    User also acknowledges the fact that data so entered into the database is for easy
                                    and ready reference for the
                                    User, and to streamline the Services through the Platform.
                                </li>
                                <li>Authorize the Platform to use, store or otherwise process certain personal
                                    information and all published
                                    Content, responses, locations, User comments, reviews and ratings for
                                    personalization of Services, marketing and
                                    promotional purposes and for optimisation of User-related options and Services.
                                </li>
                                <li>Understand and agree that, to the fullest extent permissible by law, the
                                    Platform/Company and their successors
                                    and assigns, or any of their affiliates or their respective officers, directors,
                                    employees, agents, licensors,
                                    representatives, operational service providers, advertisers or suppliers shall not
                                    be liable for any loss or
                                    damage, of any kind, direct or indirect, in connection with or arising from the use
                                    of the Platform or this
                                    terms of use, including, but not limited to, compensatory, consequential,
                                    incidental, indirect, special or
                                    punitive damages.
                                </li>
                                <li> Are bound not to cut, copy, modify, recreate, reverse engineer, distribute,
                                    disseminate, post, publish or
                                    create derivative works from, transfer, or sell any information or obtained from the
                                    Platform. Any such
                                    use/limited use of the Platform will only be allowed with the prior express written
                                    permission of the Company.
                                </li>
                                <li>Agree not to access (or attempt to access) the Platform and/or the materials or
                                    Services by any means other than
                                    through the interface provided by the Platform. The use of deep-link, robot, spider
                                    or other automatic devices,
                                    program, algorithm or methodology, or any similar or equivalent manual process, to
                                    access, acquire, copy or
                                    monitor any portion of the Platform or its content, or in any way reproduce or
                                    circumvent the navigational
                                    structure or presentation of the Platform, materials or any content, or to obtain or
                                    attempt to obtain any
                                    materials, documents or information through any means not specifically made
                                    available through the Platform will
                                    lead to suspension or termination of the User’s access to the Platform. The User
                                    acknowledges and agrees that by
                                    accessing or using the Platform or any of the Services provided therein, it may be
                                    exposed to content that it
                                    may consider offensive, indecent or otherwise objectionable. The Company disclaims
                                    any liabilities arising
                                    concerning such offensive content on the Platform.
                                </li>
                                <li>Expressly agree and acknowledge that the Content generated by the Users and
                                    displayed on the Platform is not
                                    owned by the Company and that the Company is in no way responsible for the content
                                    of the same. The User may,
                                    however, report any offensive or objectionable content, which the Company may then
                                    remove from the Platform, at
                                    its sole discretion.
                                </li>
                                <li>Expressly consents to follow the terms and conditions, and policies of the Vendor
                                    affiliated with the Company
                                    from whom the Users are utilizing services.

                                </li>
                            </ul>
                            <p>The User further undertakes not to: </p>
                            <ul>
                                <li>
                                    Engage in any activity that interferes with or disrupts access to the Platform or
                                    the Services provided therein
                                    (or the servers and networks which are connected to the Platform);
                                </li>
                                <li>Impersonate any person or entity, or falsely state or otherwise misrepresent his/her
                                    affiliation with a person
                                    or entity;
                                </li>
                                <li>Probe, scan or test the vulnerability of the Platform or any network connected to
                                    the Platform, nor breach the
                                    security or authentication measures on the Platform or any network connected to the
                                    Platform. The User may not
                                    reverse look-up, trace or seek to trace any information relating to any other User
                                    of, or visitor to, the
                                    Platform, or any other viewer of the Platform, including any User account maintained
                                    on the Platform not
                                    operated/managed by the User, or exploit the Platform or information made available
                                    or offered by or through the
                                    Platform, in any manner;
                                </li>
                                <li>Disrupt or interfere with the security of, or otherwise cause harm to, the Platform,
                                    systems resources,
                                    accounts, passwords, servers, or networks connected to or accessible through the
                                    Platform or any affiliated or
                                    linked Platforms.
                                </li>
                                <li>Use the Platform or any material or content therein for any purpose that is unlawful
                                    or prohibited by these
                                    Terms, or to solicit the performance of any illegal activity or other activity which
                                    infringes the rights of
                                    this Platform or any other third party (s);
                                </li>
                                <li>Violate any code of conduct or guideline which may apply for or to any particular
                                    service offered on the
                                    Platform;
                                </li>
                                <li>Violate any applicable laws, rules or regulations currently in force within or
                                    outside India;
                                </li>
                                <li>Violate any portion of these Terms or the Privacy Policy, including but not limited
                                    to any applicable additional
                                    terms of the Platform contained herein or elsewhere, whether made by amendment,
                                    modification, or otherwise;
                                </li>
                                <li>Commit any act that causes the Company to lose (in whole or in part) the Services of
                                    its Internet Establishment
                                    ("ISP") or in any manner disrupts the Services of any other supplier/service
                                    provider of the Company/Platform;
                                </li>
                            </ul>
                            <p> Further: </p>
                            <ul>
                                <li>
                                    The User hereby expressly authorizes the Company/Platform to disclose any and all
                                    information relating to the
                                    User in the possession of the Company/Platform to law enforcement or other
                                    government officials, as the Company
                                    may in its sole discretion, believe necessary or appropriate in connection with the
                                    investigation and/or
                                    resolution of possible crimes, especially those involve personal injury and
                                    theft/infringement of intellectual
                                    property. The User further understands that the Company/Platform might be directed
                                    to disclose any information
                                    (including the identity of persons providing information or materials on the
                                    Platform) as necessary to satisfy
                                    any judicial Order, law, regulation or valid governmental request.
                                </li>
                                <li>The User agrees to use the services provided by the Company, its affiliates,
                                    consultants and contracted
                                    companies, for lawful purposes only.
                                </li>
                                <li>The User agrees not to make any bulk purchase to indulge in any resale activities.
                                    In case of any such
                                    instances, the Company reserves all rights to cancel the current and future orders
                                    and block the concerned User
                                    account.
                                </li>
                                <li>The User agrees to provide authentic and true information. The Company reserves the
                                    right to confirm and
                                    validate the information and other details provided by the User at any point of
                                    time. If upon confirmation such
                                    User details are found to be false, not to be true (wholly or partly), the Company
                                    shall in its sole discretion
                                    reject and debar the User from using the Services available on its Website, and/or
                                    other affiliated websites
                                    without prior intimation whatsoever.

                                </li>
                                <li>The User agrees not to post any material on the website that is defamatory,
                                    offensive, obscene, indecent,
                                    abusive, or needlessly distressful, or advertising any goods or services. More
                                    specifically, the User agrees not
                                    to host, display, upload, update, publish, modify, transmit, or in any manner share
                                    any information that:
                                    <ul>
                                        <li>
                                            belongs to another person and to which the User has no right to;
                                        </li>
                                        <li> is grossly harmful, harassing, blasphemous, defamatory, obscene,
                                            pornographic, pedophilic, libellous,
                                            invasive
                                            of another's privacy, hateful, or racially, ethnically objectionable,
                                            disparaging, relating or
                                            encouraging money
                                            laundering or gambling, or otherwise unlawful in any manner whatever;
                                        </li>
                                        <li> is in any way harmful to minors;
                                        </li>
                                        <li> infringes any patent, trademark, copyright or other proprietary rights;
                                        </li>
                                        <li> violates any law for the time being in force;
                                        </li>
                                        <li> deceives or misleads the addressee about the origin of such messages or
                                            communicates any information
                                            which is
                                            grossly offensive or menacing;
                                        </li>
                                        <li> Abuse, harass, threaten, defame, disillusion, erode, abrogate, demean or
                                            otherwise violate the legal
                                            rights of
                                            others;
                                        </li>
                                        <li> Impersonate any person or entity, or falsely state or otherwise
                                            misrepresent Your affiliation with a
                                            person or
                                            entity;
                                        </li>
                                        <li> Publish, post, disseminate, any grossly harmful information, harassing,
                                            blasphemous, defamatory,
                                            obscene,
                                            pornographic, pedophilic, libellous, invasive of another's privacy, hateful,
                                            or racially, ethnically
                                            objectionable, disparaging, relating or encouraging money laundering or
                                            gambling, or otherwise unlawful
                                            in any
                                            manner whatever; or unlawfully threatening or unlawfully harassing including
                                            but not limited to
                                            "indecent
                                            representation of women" within the meaning of the Indecent Representation
                                            of Women (Prohibition) Act,
                                            1986;
                                        </li>
                                        <li> Threatens the unity, integrity, defence, security or sovereignty of India,
                                            friendly relations with
                                            foreign
                                            states, or public order or causes incitement to the commission of any
                                            cognizable offence or prevents
                                            investigation of any offence or is insulting any other nation.

                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <h3><strong>SUSPENSION OF USER ACCESS AND ACTIVITY</strong></h3>
                            <p>Notwithstanding other legal remedies that may be available, the Company may in its sole
                                discretion, limit the User’s
                                access and/or activity by immediately removing the User’s access credentials either
                                temporarily or indefinitely, or
                                suspend/terminate the User’s association with the Platform, and/or refuse to the usage
                                of the Platform to the User,
                                without being required to provide the User with notice or cause:
                            </p>
                            <ul>
                                <li>If the User is in breach any of these Terms or the Privacy Policy;
                                </li>
                                <li> If the User has provided wrong, inaccurate, incomplete or incorrect information;
                                </li>
                            </ul>
                            <p>If the User’s actions may cause any harm, damage or loss to the other Users or the
                                Company, at the sole discretion of
                                the Company.</p>

                            <h3><strong>INTELLECTUAL PROPERTY RIGHTS</strong></h3>
                            <p>Unless expressly agreed to in writing, nothing contained herein shall give the User a
                                right to use any of the
                                Platform’s trade names, trademarks, service marks, logos, domain names, information,
                                questions, answers, solutions,
                                reports and other distinctive brand features, save according to the provisions of these
                                Terms. All logos, trademarks,
                                brand names, service marks, domain names, including material, designs, and graphics
                                created by and developed by the
                                Platform and other distinctive brand features of the Platform are the property of the
                                Company or the respective
                                copyright or trademark owner. Furthermore, concerning the Platform created by the
                                Company, the Company shall be the
                                exclusive owner of all the designs, graphics and the like, related to the Platform.
                            </p>
                            <p>The User may not use any of the intellectual property displayed on the Platform in any
                                manner that is likely to cause
                                confusion among existing or prospective Users of the Platform, or that in any manner
                                disparages or discredits the
                                Company/Platform, to be determined in the sole discretion of the Company.
                            </p>
                            <p>The User is aware all intellectual property, including but not limited to copyrights,
                                relating to said services
                                resides with the owners, and that at no point does any such intellectual property stand
                                transferred from the
                                aforementioned creators to the Company or any other User. The User is aware that the
                                Company merely provides a
                                platform through which the Users can communicate and schedule meetings, and the
                                Company/Platform does not own any of
                                the intellectual property relating to the independent content displayed on the Platform,
                                apart from created graphics
                                and specified content.
                            </p>
                            <p>The User is further aware that any reproduction or infringement of the intellectual
                                property of the aforementioned
                                owners by the User will result in legal action being initiated against the User by the
                                respective owners of the
                                intellectual property so reproduced/infringed upon. It is agreed to by the Parties that
                                the contents of this Section
                                shall survive even after the termination or expiry of the Terms and/or Policy.
                            </p>
                            <h3><strong>DISCLAIMER OF WARRANTIES AND LIABILITIES</strong></h3>
                            <ul>
                                <li>
                                    The User agrees and undertakes that they are accessing the Platform at their sole
                                    risk and are that they are
                                    using their best and prudent judgment before availing any service listed on the
                                    Platform or accessing/using any
                                    information displayed thereon.
                                </li>
                                <li> The User agrees that any kind of information, resources, activities,
                                    recommendations obtained/availed from
                                    Platform, written or oral, will not create any warranty and the Platform disclaims
                                    all liabilities resulting
                                    from these.
                                </li>
                                <li> The Platform does not guarantee that the Services contained in the Platform will be
                                    uninterrupted or
                                    error-free, or that the Platform or its server will be free of viruses or other
                                    harmful components, and the User
                                    hereby expressly accepts any associated risks involved with the User’s use of the
                                    Platform.
                                </li>
                            </ul>
                            <p>It is further agreed to by the Parties that the contents of this Section shall survive
                                even after the
                                termination or expiry of the Terms and/or Policy.</p>
                            <h3><strong>FORCE MAJEURE</strong></h3>
                            <p>Neither the Company nor the Platform shall be liable for damages for any delay or failure
                                to perform its obligations
                                hereunder if such delay or failure is due to cause beyond its control or without its
                                fault or negligence, due to
                                Force Majeure events including but not limited to acts of war, acts of God, earthquake,
                                riot, fire, festive
                                activities sabotage, labour shortage or dispute, internet interruption, technical
                                failure, breakage of sea cable,
                                hacking, piracy, cheating, illegal or unauthorized.
                            </p>
                            <h3><strong>DISPUTE RESOLUTION AND JURISDICTION
                                </strong></h3>
                            <p>It is expressly agreed to by the Parties hereto that the formation, interpretation, and
                                performance of these Terms
                                and any disputes arising therefrom will be resolved through a two-step Alternate Dispute
                                Resolution (“ADR”)
                                mechanism. It is further agreed to by the Parties that the contents of this Section
                                shall survive even after the
                                termination or expiry of the Terms and/or Policy. </p>
                            <ul>
                                <li><strong>Mediation:</strong> In case of any dispute between the parties, the Parties
                                    will attempt to resolve the
                                    same amicably amongst themselves, to the mutual satisfaction of all parties. In the
                                    event that the Parties are
                                    unable to reach such an amicable solution within thirty (30) days of one Party
                                    communicating the existence of a
                                    dispute to any other Party, the dispute will be resolved by arbitration, as detailed
                                    hereinbelow;
                                </li>
                                <li> <strong> Arbitration:</strong> In the event that the Parties are unable to amicably
                                    resolve a dispute by
                                    mediation, said dispute will be referred to arbitration by a sole arbitrator to be
                                    appointed by the Company, and
                                    the award passed by such sole arbitrator will be valid and binding on all parties.
                                    The Parties shall bear their
                                    own costs for the proceedings, although the sole arbitrator may, in his/her sole
                                    discretion, direct either Party
                                    to bear the entire cost of the proceedings. The arbitration shall be conducted in
                                    English, and the seat of
                                    Arbitration shall be the city of <strong> New Delhi, India.</strong>
                                </li>
                            </ul>
                            <p>The Parties expressly agree that the Terms of Service, Privacy Policy and any other
                                agreements entered into between
                                the Parties are governed by the laws, rules, and regulations of India.</p>
                            <h3><strong>NOTICES/GRIEVANCES</strong></h3>
                            <p>Any and all communication relating to any dispute or grievance experienced by the User
                                may be communicated to the
                                Company by the User by emailing to iout364@yahoo.com</p>
                            <h3><strong>MISCELLANEOUS PROVISIONS</strong></h3>
                            <ul>
                                <li>
                                    <strong>Entire Agreement:</strong> These Terms, read with the Policy, form the
                                    complete and final contract
                                    between the User and
                                    the Company with respect to the subject matter hereof and supersedes all other
                                    communications, representations,
                                    and agreements (whether oral, written or otherwise) relating thereto.
                                </li>
                                <li> <strong>Waiver:</strong> The failure of either Party at any time to require
                                    performance of any provision of
                                    these Terms shall in
                                    no manner affect such Party's right at a later time to enforce the same. No waiver
                                    by either party of any breach
                                    of these Terms, whether by conduct or otherwise, in any one or more instances, shall
                                    be deemed to be or
                                    construed as a further or continuing waiver of any such breach, or a waiver of any
                                    other breach of these Terms.
                                </li>
                                <li> <strong>Severability:</strong> If any provision/clause of these Terms is held to be
                                    invalid, illegal or
                                    unenforceable by any
                                    court or authority of competent jurisdiction, the validity, legality, and
                                    enforceability of the remaining
                                    provisions/clauses of these Terms shall in no way be affected or impaired thereby,
                                    and each such
                                    provision/clause of these Terms shall be valid and enforceable to the fullest extent
                                    permitted by law. In such
                                    case, these Terms shall be reformed to the minimum extent necessary to correct any
                                    invalidity, illegality or
                                    unenforceability, while preserving to the maximum extent the original rights,
                                    intentions and commercial
                                    expectations of the Parties hereto, as expressed herein.
                                </li>
                                <li> <strong>Contact Us:</strong> If you have any questions about this Policy or any
                                    other policy, the practices of
                                    the Platform, or
                                    your experience, you can contact us at info@ultrasound.net.in
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col75" *ngIf="showPrivacyPolicy">
                        <div class="profile-right profile_content content-tab">
                            <a href="javascript:void(0)" class="back-btn"
                                [routerLink]="['/dashboard/my-course']">Back</a>
                            <h2 class="section-title">Privacy Policy </h2>
                            <p>
                                This Mobile Application is created and operated by <strong>Institute of Ultrasound
                                    Training</strong> having its
                                registered office at
                                <strong>“511-12, Westend Mall, Janakpuri, New Delhi-110058 India”</strong> represented
                                by its Directors, hereinafter
                                referred to as
                                the <strong>“Company” </strong> (where such expression shall, unless repugnant to the
                                context thereof, be deemed to
                                include its
                                respective legal heirs, representatives, administrators, permitted successors and
                                assigns).
                                This legal agreement is an electronic record in terms of Indian Information Technology
                                Act, 2000 and rules there
                                under as applicable and the amended provisions about electronic records in various
                                statutes as amended by the Indian
                                Information Technology Act, 2000. This electronic record is generated by a computer
                                system and does not require any
                                physical or digital signatures.</p>
                            <p>
                                This legal document is published in accordance with the provisions of Rule 3 (1) of the
                                Indian Information
                                Technology (Intermediaries guidelines) Rules, 2011 and Rule 4 of the Information
                                Technology (Reasonable security
                                practices and procedures and sensitive personal data or information) Rules, 2011 of
                                Information Technology Act, 2000
                                amended through Information Technology Amendment Act, 2008 that require publishing the
                                Terms of Services and
                                practices for access or usage of <strong>“drrandhawainstitute” (“Mobile
                                    Application/platform”)</strong>

                            </p>
                            <p>
                                The creator of this Privacy Policy ensures a steady commitment to Your privacy with
                                regard to the protection of your invaluable information that you may share across on
                                this platform. This privacy policy contains information about the Mobile Application
                                <strong>“drrandhawainstitute”</strong> (hereinafter referred to as the
                                <strong>“Platform”</strong>)

                            </p>
                            <p>
                                To provide You with Our uninterrupted use of the platform, We may collect and, in some
                                circumstances, disclose
                                information about you with your permission. To ensure better protection of Your privacy,
                                We provide this notice
                                explaining Our information collection and disclosure policies, and the choices You make
                                about the way Your
                                information is collected and used.
                            </p>
                            <p>
                                <strong>This Privacy Policy shall be in compliance with the General Data Protection
                                    Regulation (GDPR) in effect from
                                    May 25, 2018, and any and all provisions that may read to the contrary shall be
                                    deemed to be void and
                                    unenforceable as of that date. If you do not agree with the terms and conditions of
                                    our Privacy Policy,
                                    including in relation to the manner of collection or use of your information, please
                                    do not use or access the
                                    Site. If you have any questions or concerns regarding this Privacy Policy, you
                                    should contact our Customer
                                    Support Desk at info@ultrasound.net.in.
                                </strong>
                            </p>
                            <p>ANY CAPITALIZED WORDS USED HENCEFORTH SHALL HAVE THE MEANING ACCORDED TO THEM UNDER THIS
                                AGREEMENT. FURTHER, ALL
                                HEADING USED
                                HEREIN ARE ONLY FOR THE PURPOSE OF ARRANGING THE VARIOUS PROVISIONS OF THE AGREEMENT IN
                                ANY MANNER. NEITHER THE USER
                                NOR THE CREATORS OF THIS PRIVACY POLICY MAY USE THE HEADING TO INTERPRET THE PROVISIONS
                                CONTAINED WITHIN IT IN ANY
                                MANNER.
                            </p>
                            <h3><strong>INFORMATION WE COLLECT</strong></h3>
                            <p>We are committed to respecting Your online privacy. We further recognize Your need for
                                appropriate protection and
                                management of any Personal Information You share with us. We may collect the following
                                information:

                            </p>


                            <ul>
                                <li>
                                    Personal data including but not limited to Name, Mobile No, Email Id and Message and
                                    Inquiry details.</li>
                                <li>Device Information such gallery, access to camera etc. </li>
                                <li>Tracking Information such as, but not limited to the IP address of your device and
                                    Device ID when connected to
                                    the Internet. This information may include the URL that you just came from (whether
                                    this URL is on the Platform
                                    or not), which URL you next go to (whether this URL is on the Platform or not), your
                                    computer or device browser
                                    information, and other information associated with your interaction with the
                                    Platform;

                                </li>
                                <li>Details of Platform usage for analytics.
                                </li>
                            </ul>
                            <p>This privacy policy also applies to data we collect from users who are not registered as
                                members of this Platform,
                                including, but not limited to, browsing behaviour, pages viewed etc. We also collect and
                                store personal information
                                provided by You from time to time on the Platform. We only collect and use such
                                information from you that we
                                consider necessary for achieving a seamless, efficient and safe experience, customized
                                to your needs including:</p>

                            <ul>
                                <li> To enable the provision of services opted for by you; </li>
                                <li>To enable the viewing of content in your interest; </li>
                                <li>To communicate the necessary account and service related information from time to
                                    time; </li>
                                <li>To allow you to receive quality customer care services and data Collection; </li>
                                <li>To comply with applicable laws, rules and regulations;</li>
                            </ul>

                            <p>
                                Where any service requested by You involves a third party, such information as is
                                reasonably necessary by the
                                Company to carry out Your service request may be shared with such third party. The
                                Company may also use contact
                                information internally to direct its efforts for service improvement but shall
                                immediately delete all such
                                information upon withdrawal of your consent for the same through the
                                <strong>‘unsubscribe’</strong> button or
                                through an email to be
                                sent to <em><strong>info@ultrasound.net.in.</strong></em>
                            </p>
                            <p>To the extent possible, we provide You with the option of not divulging any specific
                                information that you wish for us
                                not to collect, store or use. You may also choose not to use a particular service or
                                feature on the Platform and
                                opt-out of any non-essential communications from the platform.

                            </p>
                            <p>Further, transacting over the internet has inherent risks which can only be avoided by
                                you following security
                                practices yourself, such as not revealing account/login related information to any other
                                person and informing our
                                customer care team about any suspicious activity or where your account has/may have been
                                compromised.
                            </p>
                            <h3><strong>OUR USE OF YOUR INFORMATION</strong></h3>
                            <p>The information provided by you shall be used to provide and improve the service for you
                                and all users.
                            </p>
                            <ul>
                                <li>To provide you with services on your request.</li>
                                <li> For maintaining an internal record.</li>
                                <li> For enhancing the Services provided.</li>
                                <li> For maintaining record under the legal and statutory provisions.
                                </li>
                            </ul>
                            <p>For more details about the nature of such communications, please refer to our Terms of
                                Service. Further, your data
                                and Sensitive Personal data may be collected and stored by Us for internal record.
                            </p>
                            <p>We use Your tracking information such as IP addresses, and or Device ID to help identify
                                you and to gather broad
                                demographic information and make further services available to you.
                            </p>
                            <p>We will not sell, license or trade Your personal information. We will not share your
                                personal information with others
                                unless they are acting under our instructions or we are required to do so by law.
                            </p>
                            <p>Information collected via Our server logs includes users' IP addresses and the pages
                                visited; this will be used to
                                manage the web system and troubleshoot problems. We also use third-party analytics,
                                tracking, optimization and
                                targeting tools to understand how users engage with our Platform so that we can improve
                                it and cater personalized
                                content/ads according to their preferences.
                            </p>
                            <h3><strong>HOW INFORMATION IS COLLECTED</strong></h3>
                            <p>Before or at the time of collecting personal information, we will identify the purposes
                                for which information is
                                being collected. If the same is not identified to you, you have the right to request the
                                Company to elucidate the
                                purpose of collection of said personal information, pending the fulfilment of which you
                                shall not be mandated to
                                disclose any information whatsoever.
                            </p>
                            <p>We will collect and use your personal information solely to fulfil those purposes
                                specified by us, within the scope
                                of the consent of the individual concerned or as required by law. We will only retain
                                personal information as long
                                as necessary for the fulfilment of those purposes. We will collect personal information
                                by lawful and fair means and
                                with the knowledge and consent of the individual concerned.
                            </p>
                            <p>Personal data should be relevant to the purposes for which it is to be used, and, to the
                                extent necessary for those
                                purposes, should be accurate, complete, and up-to-date.
                            </p>
                            <h3><strong>EXTERNAL LINKS ON THE PLATFORM</strong></h3>

                            <p>The Platform may include advertisements, hyperlinks to other Mobile Applications or
                                resources. We have no control
                                over any other Mobile Application or resources or contents available on these other
                                Mobile Applications, which are
                                provided by companies or persons other than Us. You acknowledge and agree that we are
                                not responsible for the
                                availability of any such external sites or resources, and do not endorse any
                                advertising, services/services or other
                                materials on or available from such platform or resources. You acknowledge and agree
                                that We are not liable for any
                                loss or damage which may be incurred by you as a result of the availability of those
                                external sites or resources, or
                                as a result of any reliance placed by you on the completeness, accuracy or existence of
                                any advertising, services or
                                other materials on, or available from, such Mobile Applications. These external third
                                party Mobile Applications and
                                resource providers may have their privacy policies governing the collection, storage,
                                retention and disclosure of
                                Your Personal Information that You may be subject to. We recommend that you enter the
                                Mobile Application and review
                                their privacy policy.</p>
                            <h3><strong>YOUR RIGHTS</strong></h3>
                            <p>Unless subject to an exemption, you have the following rights concerning your data:
                            </p>
                            <ul>
                                <li>
                                    The right to request a copy of your data which we hold about you; </li>
                                <li>The right to request for any correction to any personal data if it is found to
                                    beinaccurate or out of date;
                                </li>
                                <li>The right to withdraw Your consent to the processing at any time; </li>
                                <li>The right to object to the processing of personal data; </li>
                                <li>The right to complain about a supervisory authority. </li>
                                <li>The right to obtain information as to whether personal data are transferred toa
                                    third country or an
                                    international organization.

                                </li>
                            </ul>
                            <p>Where you hold an account with any of our services, you are entitled to a copy of all
                                personal data which we hold
                                concerning you. You are also entitled to request that we restrict how we use your data
                                in your account when you log
                                in.

                            </p>
                            <h3><strong>CONFIDENTIALITY</strong></h3>
                            <p>You further acknowledge that the Platform may contain information which is designated
                                confidential by us and that you
                                shall not disclose such information without our prior written consent. Your information
                                is regarded as confidential
                                and therefore will not be divulged to any third party, unless if legally required to do
                                so to the appropriate
                                authorities. We will not sell, share, or rent your personal information to any third
                                party or use your e-mail
                                address for unsolicited mail. Any emails sent by us will only be in connection with the
                                provision of agreed
                                services, and you retain sole discretion to seek for discontinuation of such
                                communications at any point of time.
                            </p>
                            <h3><strong>OTHER INFORMATION COLLECTORS
                                </strong></h3>
                            <p>Except as otherwise expressly included in this Privacy Policy, this document only
                                addresses the use and disclosure of
                                information we collect from you. To the extent that you disclose your information to
                                other parties, whether they are
                                on our Platform or other sites throughout the Internet, different rules may apply to
                                their use or disclosure of the
                                information you disclose to them. To the extent that we use third party advertisers,
                                they adhere to their privacy
                                policies. Since we do not control the privacy policies of the third parties, you are
                                subject to ask questions before
                                you disclose your personal information to others.
                            </p>
                            <h3><strong>COOKIES
                                </strong></h3>
                            <p>We use data collection devices such as “cookies” on certain pages of our Mobile
                                Application. “Cookies” are small
                                files sited on your hard drive that assist us in providing customized services. We also
                                offer certain features that
                                are only available through the use of a “cookie”. Cookies can also help us provide
                                information which is targeted to
                                your interests. Cookies may be used to identify logged in or registered users. Our
                                Mobile Application uses session
                                cookies to ensure that you have a good experience. These cookies contain a unique
                                number, your 'session ID', which
                                allows our server to recognize your computer and 'remember' what you've done on the
                                site. The benefits of this are:
                                a. You only need to log in once if you're navigating secure areas of the site b. Our
                                server can distinguish between
                                your computer and other users, so you can see the information that you have requested.
                                You can choose to accept or
                                decline cookies by modifying your browser settings if you prefer. This may prevent you
                                from taking full advantage of
                                the Mobile Application. We also use various third-party cookies for usage, behavioral,
                                analytics and preferences
                                data.
                            </p>
                            <p>The following are the different types of cookies used on the Mobile Application:</p>
                            <ul>
                                <li>Authentication cookies: To identify the user and share the content that he or she
                                    requested. b. Functionality
                                    cookies: For the customized user experience and resuming past course progress. c.
                                    Tracking, optimization, and
                                    targeting cookies: To capture usage metric on the device, operating system, browser,
                                    etc. To capture behavioral
                                    metrics for better content delivery. To cater and suggest most suited services and
                                    services.
                                </li>
                            </ul>
                            <h3><strong>OUR DISCLOSURE OF YOUR INFORMATION
                                </strong></h3>

                            <p>We may host surveys for survey creators for our platform who are the owners and users of
                                your survey responses. We do
                                not own or sell your responses. Anything you expressly disclose in your responses will
                                be disclosed to survey
                                creators. Please contact the survey creator directly to better understand how they might
                                share your survey
                                responses.
                            </p>
                            <p>Information collected will not be considered as sensitive if it is freely available and
                                accessible in the public
                                domain or is furnished under the Right to Information Act, 2005, any rules made
                                thereunder or any other law for the
                                time being in force.
                            </p>
                            <p>Due to the existing regulatory environment, we cannot ensure that all of your private
                                communications and other
                                personally identifiable information will never be disclosed in ways not otherwise
                                described in this Privacy Policy.
                                By way of example (without limiting and foregoing), we may be forced to disclose
                                information to the government, law
                                enforcement agencies or third parties. Therefore, although we use industry-standard
                                practices to protect your
                                privacy, we do not promise, and you should not expect, that your personally identifiable
                                information or private
                                communications would always remain private. We do however assure you that any disclosure
                                of your personally
                                identifiable information shall be personally intimated to you through an email sent to
                                your provided email address.
                            </p>
                            <p>As a matter of policy, we do not sell or rent any personally identifiable information
                                about you to any third party.
                                However, the following describes some of the ways that your personally identifiable
                                information may be disclosed:

                            </p>
                            <ul>
                                <li>
                                    External Service Providers: There may be several services offered by external
                                    service providers that help you
                                    use our Platform. If you choose to use these optional services, and in the course of
                                    doing so, disclose
                                    information to the external service providers, and/or permit them to collect
                                    information about you, then their
                                    use of your information is governed by their privacy policy.

                                </li>
                                <li>Law and Order: We cooperate with law enforcement inquiries, as well as other third
                                    parties to enforce laws, such
                                    as intellectual property rights, fraud and other rights. We can (and you authorize
                                    us to) disclose any
                                    information about you to law enforcement and other government officials as we, in
                                    our sole discretion, believe
                                    necessary or appropriate, in connection with an investigation of fraud, intellectual
                                    property infringements, or
                                    other activity that is illegal or may expose us or you to legal liability.</li>
                            </ul>
                            <h3><strong>ACCESSING, REVIEWING AND CHANGING YOUR PROFILE</strong></h3>
                            <p>
                                Following registration, you can review and change the information you submitted at the
                                stage of except Email ID and
                                mobile number. An option for facilitating such change shall be present on the Platform
                                and such change shall be
                                facilitated by the User. If you change any information, we may or may not keep track of
                                your old information. We
                                will not retain in our files information you have requested to remove for certain
                                circumstances, such as to resolve
                                disputes, troubleshoot problems and enforce our terms and conditions. Such prior
                                information shall be completely
                                removed from our databases, including stored ‘back up’ systems. If you believe that any
                                information, we are holding
                                on you is incorrect or incomplete, or to remove your profile so that others cannot view
                                it, the User needs to
                                remediate, and promptly correct any such incorrect information.
                            </p>
                            <h3><strong>SECURITY</strong></h3>
                            <p>
                                We treat data as an asset that must be protected against loss and unauthorized access.
                                We employ many different
                                security techniques to protect such data from unauthorized access by members inside and
                                outside the Company. We
                                follow generally accepted industry standards to protect the Personal Information
                                submitted to us and information
                                that we have accessed.
                                However, as effective as encryption technology is, no security system is impenetrable.
                                Our Company cannot guarantee
                                the security of our database, nor can we guarantee that information you provide won’t be
                                intercepted while being
                                transmitted to the Company over the Internet.
                            </p>
                            <h3><strong>SEVERABILITY</strong></h3>
                            <p>
                                Each paragraph of this Privacy Policy shall be and remain separate from and independent
                                of and severable from all
                                and any other paragraphs herein except where otherwise expressly indicated or indicated
                                by the context of the
                                agreement. The decision or declaration that one or more of the paragraphs are null and
                                void shall not affect the
                                remaining paragraphs of this privacy policy.
                            </p>
                            <h3><strong>AMENDMENT</strong></h3>
                            <p>
                                Our Privacy Policy may change from time to time. The most current version of the policy
                                will govern our use of your
                                information and will always be at the Platform. Any amendments to this Policy shall be
                                deemed as accepted by the
                                User on their continued use of the Platform.
                            </p>
                            <h3><strong>CONSENT WITHDRAWAL, DATA DOWNLOAD & DATA REMOVAL REQUESTS</strong></h3>
                            <p>
                                To withdraw your consent, or to request the download or delete your data with us for any
                                or all our services at any
                                time, please email to <strong>info@ultrasound.net.in</strong>
                            </p>
                            <h3><strong>CONTACT US</strong></h3>
                            <p>
                                If you have any questions or concerns regarding this privacy policy, you should contact
                                us by sending an e-mail to
                                please email to <strong>info@ultrasound.net.in</strong></p>
                            <p> Information provided on the Platform may not be accurate and may be provided for
                                promotional purposes of the
                                business.
                            </p>
                        </div>
                    </div> -->
                </form>
            </div>
        </div>
    </div>
</div>