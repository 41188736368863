import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AssessmentComponent } from './AfterLogin_Components/assessment/assessment.component';
import { ChangePasswordComponent } from './AfterLogin_Components/change-password/change-password.component';
import { ChaptersComponent } from './AfterLogin_Components/chapters/chapters.component';
import { MyCourseComponent } from './AfterLogin_Components/my-course/my-course.component';
import { MyProfileComponent } from './AfterLogin_Components/my-profile/my-profile.component';
import { PageNotFoundComponent } from './AfterLogin_Components/page-not-found/page-not-found.component';
import { AuthenticationGuard } from './Authentication/authentication.guard';
import { DashboardComponent } from './Login_Fllow_Components/dashboard/dashboard.component';
import { ForgotComponent } from './Login_Fllow_Components/forgot/forgot.component';
import { LoginComponent } from './Login_Fllow_Components/login/login.component';
import { ResetPasswordComponent } from './Login_Fllow_Components/reset-password/reset-password.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'dashboard', component: DashboardComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
      { path: 'my-course', component: MyCourseComponent, canActivate: [AuthenticationGuard] },
      { path: 'forgot-Password', component: ForgotComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'change-password', component: ChangePasswordComponent },
      { path: 'my-profile', component: MyProfileComponent, canActivate: [AuthenticationGuard] },
      { path: 'page-not-found', component: PageNotFoundComponent },
      { path: 'chapters', component: ChaptersComponent, canActivate: [AuthenticationGuard] },
      { path: 'Assessment', component: AssessmentComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'terms-conditions', component: TermsConditionsComponent},
      { path: '**', redirectTo: 'page-not-found' }
      
    ]
  }


  // {
  //   path:'',component:HeaderComponent,
  //   children:[
  //     {path:'',redirectTo:'dashboard', pathMatch:'full'},
  //     {path:'dashboard', component: LoginComponent},
  //     {path:'my-course', component:MyCourseComponent}
  //   ]
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
