import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'viemoUrl'
})
export class ViemoUrlPipe implements PipeTransform {
  constructor(protected _sanitizer: DomSanitizer) { }

  transform(value: any, args?: any): any {

    if (value.length != 0) {
      //   var a = value.split('/');
      //   if (a[2] == 'vimeo.com') {
      let url = value.replace("vimeo.com/", "player.vimeo.com/video/");
      return this._sanitizer.bypassSecurityTrustResourceUrl(url);
      //   }

    }




  }

}
