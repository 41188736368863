import { Component, OnInit } from '@angular/core';
import { ApiService } from '../Api_Service/api.service';
import { endpoint } from '../Constants/endpoints';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  loginStatus: boolean=false;
  profile: any;

  constructor(private apiService:ApiService) { }

  ngOnInit(): void {
    this.checkToken();
    this.getProfile();
  }



  // Check Token ** //
  checkToken(){
    if(localStorage.getItem('token')){
      this.loginStatus=true;
    }else{
      this.loginStatus=false;
    }
  }


  getProfile() {
    if(localStorage.getItem('token')){
      this.apiService.getApi(endpoint.profile, 1).subscribe(res => {
        if (res.body.code || res.responseCode == 200) {
          this.profile = res.body.userdata
          console.log('profile',this.profile)
         
        }
      }, (error) => {
       
      })
    }
  }
}







