
<div id="wrapper">
<div class="content">
    <div class="inner-banner" style="background-image: url(../../assets/images/Image_61.png);">
        <div class="container">
            <h1 class="page-title">Privacy Policy</h1>
        </div>
    </div>

    <div class="profile-right profile_content content-tab">
        <div class="container">
            <p>
                This Mobile Application is created and operated by <strong>Institute of Ultrasound
                    Training</strong> having its
                registered office at
                <strong>“511-12, Westend Mall, Janakpuri, New Delhi-110058 India”</strong> represented
                by its Directors, hereinafter
                referred to as
                the <strong>“Company” </strong> (where such expression shall, unless repugnant to the
                context thereof, be deemed to
                include its
                respective legal heirs, representatives, administrators, permitted successors and
                assigns).
                This legal agreement is an electronic record in terms of Indian Information Technology
                Act, 2000 and rules there
                under as applicable and the amended provisions about electronic records in various
                statutes as amended by the Indian
                Information Technology Act, 2000. This electronic record is generated by a computer
                system and does not require any
                physical or digital signatures.</p>
            <p>
                This legal document is published in accordance with the provisions of Rule 3 (1) of the
                Indian Information
                Technology (Intermediaries guidelines) Rules, 2011 and Rule 4 of the Information
                Technology (Reasonable security
                practices and procedures and sensitive personal data or information) Rules, 2011 of
                Information Technology Act, 2000
                amended through Information Technology Amendment Act, 2008 that require publishing the
                Terms of Services and
                practices for access or usage of <strong>“drrandhawainstitute” (“Mobile
                    Application/platform”)</strong>
    
            </p>
            <p>
                The creator of this Privacy Policy ensures a steady commitment to Your privacy with
                regard to the protection of your invaluable information that you may share across on
                this platform. This privacy policy contains information about the Mobile Application
                <strong>“drrandhawainstitute”</strong> (hereinafter referred to as the
                <strong>“Platform”</strong>)
    
            </p>
            <p>
                To provide You with Our uninterrupted use of the platform, We may collect and, in some
                circumstances, disclose
                information about you with your permission. To ensure better protection of Your privacy,
                We provide this notice
                explaining Our information collection and disclosure policies, and the choices You make
                about the way Your
                information is collected and used.
            </p>
            <p>
                <strong>This Privacy Policy shall be in compliance with the General Data Protection
                    Regulation (GDPR) in effect from
                    May 25, 2018, and any and all provisions that may read to the contrary shall be
                    deemed to be void and
                    unenforceable as of that date. If you do not agree with the terms and conditions of
                    our Privacy Policy,
                    including in relation to the manner of collection or use of your information, please
                    do not use or access the
                    Site. If you have any questions or concerns regarding this Privacy Policy, you
                    should contact our Customer
                    Support Desk at info@ultrasound.net.in.
                </strong>
            </p>
            <p>ANY CAPITALIZED WORDS USED HENCEFORTH SHALL HAVE THE MEANING ACCORDED TO THEM UNDER THIS
                AGREEMENT. FURTHER, ALL
                HEADING USED
                HEREIN ARE ONLY FOR THE PURPOSE OF ARRANGING THE VARIOUS PROVISIONS OF THE AGREEMENT IN
                ANY MANNER. NEITHER THE USER
                NOR THE CREATORS OF THIS PRIVACY POLICY MAY USE THE HEADING TO INTERPRET THE PROVISIONS
                CONTAINED WITHIN IT IN ANY
                MANNER.
            </p>
            <h3><strong>INFORMATION WE COLLECT</strong></h3>
            <p>We are committed to respecting Your online privacy. We further recognize Your need for
                appropriate protection and
                management of any Personal Information You share with us. We may collect the following
                information:
    
            </p>
    
    
            <ul>
                <li>
                    Personal data including but not limited to Name, Mobile No, Email Id and Message and
                    Inquiry details.</li>
                <li>Device Information such gallery, access to camera etc. </li>
                <li>Tracking Information such as, but not limited to the IP address of your device and
                    Device ID when connected to
                    the Internet. This information may include the URL that you just came from (whether
                    this URL is on the Platform
                    or not), which URL you next go to (whether this URL is on the Platform or not), your
                    computer or device browser
                    information, and other information associated with your interaction with the
                    Platform;
    
                </li>
                <li>Details of Platform usage for analytics.
                </li>
            </ul>
            <p>This privacy policy also applies to data we collect from users who are not registered as
                members of this Platform,
                including, but not limited to, browsing behaviour, pages viewed etc. We also collect and
                store personal information
                provided by You from time to time on the Platform. We only collect and use such
                information from you that we
                consider necessary for achieving a seamless, efficient and safe experience, customized
                to your needs including:</p>
    
            <ul>
                <li> To enable the provision of services opted for by you; </li>
                <li>To enable the viewing of content in your interest; </li>
                <li>To communicate the necessary account and service related information from time to
                    time; </li>
                <li>To allow you to receive quality customer care services and data Collection; </li>
                <li>To comply with applicable laws, rules and regulations;</li>
            </ul>
    
            <p>
                Where any service requested by You involves a third party, such information as is
                reasonably necessary by the
                Company to carry out Your service request may be shared with such third party. The
                Company may also use contact
                information internally to direct its efforts for service improvement but shall
                immediately delete all such
                information upon withdrawal of your consent for the same through the
                <strong>‘unsubscribe’</strong> button or
                through an email to be
                sent to <em><strong>info@ultrasound.net.in.</strong></em>
            </p>
            <p>To the extent possible, we provide You with the option of not divulging any specific
                information that you wish for us
                not to collect, store or use. You may also choose not to use a particular service or
                feature on the Platform and
                opt-out of any non-essential communications from the platform.
    
            </p>
            <p>Further, transacting over the internet has inherent risks which can only be avoided by
                you following security
                practices yourself, such as not revealing account/login related information to any other
                person and informing our
                customer care team about any suspicious activity or where your account has/may have been
                compromised.
            </p>
            <h3><strong>OUR USE OF YOUR INFORMATION</strong></h3>
            <p>The information provided by you shall be used to provide and improve the service for you
                and all users.
            </p>
            <ul>
                <li>To provide you with services on your request.</li>
                <li> For maintaining an internal record.</li>
                <li> For enhancing the Services provided.</li>
                <li> For maintaining record under the legal and statutory provisions.
                </li>
            </ul>
            <p>For more details about the nature of such communications, please refer to our Terms of
                Service. Further, your data
                and Sensitive Personal data may be collected and stored by Us for internal record.
            </p>
            <p>We use Your tracking information such as IP addresses, and or Device ID to help identify
                you and to gather broad
                demographic information and make further services available to you.
            </p>
            <p>We will not sell, license or trade Your personal information. We will not share your
                personal information with others
                unless they are acting under our instructions or we are required to do so by law.
            </p>
            <p>Information collected via Our server logs includes users' IP addresses and the pages
                visited; this will be used to
                manage the web system and troubleshoot problems. We also use third-party analytics,
                tracking, optimization and
                targeting tools to understand how users engage with our Platform so that we can improve
                it and cater personalized
                content/ads according to their preferences.
            </p>
            <h3><strong>HOW INFORMATION IS COLLECTED</strong></h3>
            <p>Before or at the time of collecting personal information, we will identify the purposes
                for which information is
                being collected. If the same is not identified to you, you have the right to request the
                Company to elucidate the
                purpose of collection of said personal information, pending the fulfilment of which you
                shall not be mandated to
                disclose any information whatsoever.
            </p>
            <p>We will collect and use your personal information solely to fulfil those purposes
                specified by us, within the scope
                of the consent of the individual concerned or as required by law. We will only retain
                personal information as long
                as necessary for the fulfilment of those purposes. We will collect personal information
                by lawful and fair means and
                with the knowledge and consent of the individual concerned.
            </p>
            <p>Personal data should be relevant to the purposes for which it is to be used, and, to the
                extent necessary for those
                purposes, should be accurate, complete, and up-to-date.
            </p>
            <h3><strong>EXTERNAL LINKS ON THE PLATFORM</strong></h3>
    
            <p>The Platform may include advertisements, hyperlinks to other Mobile Applications or
                resources. We have no control
                over any other Mobile Application or resources or contents available on these other
                Mobile Applications, which are
                provided by companies or persons other than Us. You acknowledge and agree that we are
                not responsible for the
                availability of any such external sites or resources, and do not endorse any
                advertising, services/services or other
                materials on or available from such platform or resources. You acknowledge and agree
                that We are not liable for any
                loss or damage which may be incurred by you as a result of the availability of those
                external sites or resources, or
                as a result of any reliance placed by you on the completeness, accuracy or existence of
                any advertising, services or
                other materials on, or available from, such Mobile Applications. These external third
                party Mobile Applications and
                resource providers may have their privacy policies governing the collection, storage,
                retention and disclosure of
                Your Personal Information that You may be subject to. We recommend that you enter the
                Mobile Application and review
                their privacy policy.</p>
            <h3><strong>YOUR RIGHTS</strong></h3>
            <p>Unless subject to an exemption, you have the following rights concerning your data:
            </p>
            <ul>
                <li>
                    The right to request a copy of your data which we hold about you; </li>
                <li>The right to request for any correction to any personal data if it is found to
                    beinaccurate or out of date;
                </li>
                <li>The right to withdraw Your consent to the processing at any time; </li>
                <li>The right to object to the processing of personal data; </li>
                <li>The right to complain about a supervisory authority. </li>
                <li>The right to obtain information as to whether personal data are transferred toa
                    third country or an
                    international organization.
    
                </li>
            </ul>
            <p>Where you hold an account with any of our services, you are entitled to a copy of all
                personal data which we hold
                concerning you. You are also entitled to request that we restrict how we use your data
                in your account when you log
                in.
    
            </p>
            <h3><strong>CONFIDENTIALITY</strong></h3>
            <p>You further acknowledge that the Platform may contain information which is designated
                confidential by us and that you
                shall not disclose such information without our prior written consent. Your information
                is regarded as confidential
                and therefore will not be divulged to any third party, unless if legally required to do
                so to the appropriate
                authorities. We will not sell, share, or rent your personal information to any third
                party or use your e-mail
                address for unsolicited mail. Any emails sent by us will only be in connection with the
                provision of agreed
                services, and you retain sole discretion to seek for discontinuation of such
                communications at any point of time.
            </p>
            <h3><strong>OTHER INFORMATION COLLECTORS
                </strong></h3>
            <p>Except as otherwise expressly included in this Privacy Policy, this document only
                addresses the use and disclosure of
                information we collect from you. To the extent that you disclose your information to
                other parties, whether they are
                on our Platform or other sites throughout the Internet, different rules may apply to
                their use or disclosure of the
                information you disclose to them. To the extent that we use third party advertisers,
                they adhere to their privacy
                policies. Since we do not control the privacy policies of the third parties, you are
                subject to ask questions before
                you disclose your personal information to others.
            </p>
            <h3><strong>COOKIES
                </strong></h3>
            <p>We use data collection devices such as “cookies” on certain pages of our Mobile
                Application. “Cookies” are small
                files sited on your hard drive that assist us in providing customized services. We also
                offer certain features that
                are only available through the use of a “cookie”. Cookies can also help us provide
                information which is targeted to
                your interests. Cookies may be used to identify logged in or registered users. Our
                Mobile Application uses session
                cookies to ensure that you have a good experience. These cookies contain a unique
                number, your 'session ID', which
                allows our server to recognize your computer and 'remember' what you've done on the
                site. The benefits of this are:
                a. You only need to log in once if you're navigating secure areas of the site b. Our
                server can distinguish between
                your computer and other users, so you can see the information that you have requested.
                You can choose to accept or
                decline cookies by modifying your browser settings if you prefer. This may prevent you
                from taking full advantage of
                the Mobile Application. We also use various third-party cookies for usage, behavioral,
                analytics and preferences
                data.
            </p>
            <p>The following are the different types of cookies used on the Mobile Application:</p>
            <ul>
                <li>Authentication cookies: To identify the user and share the content that he or she
                    requested. b. Functionality
                    cookies: For the customized user experience and resuming past course progress. c.
                    Tracking, optimization, and
                    targeting cookies: To capture usage metric on the device, operating system, browser,
                    etc. To capture behavioral
                    metrics for better content delivery. To cater and suggest most suited services and
                    services.
                </li>
            </ul>
            <h3><strong>OUR DISCLOSURE OF YOUR INFORMATION
                </strong></h3>
    
            <p>We may host surveys for survey creators for our platform who are the owners and users of
                your survey responses. We do
                not own or sell your responses. Anything you expressly disclose in your responses will
                be disclosed to survey
                creators. Please contact the survey creator directly to better understand how they might
                share your survey
                responses.
            </p>
            <p>Information collected will not be considered as sensitive if it is freely available and
                accessible in the public
                domain or is furnished under the Right to Information Act, 2005, any rules made
                thereunder or any other law for the
                time being in force.
            </p>
            <p>Due to the existing regulatory environment, we cannot ensure that all of your private
                communications and other
                personally identifiable information will never be disclosed in ways not otherwise
                described in this Privacy Policy.
                By way of example (without limiting and foregoing), we may be forced to disclose
                information to the government, law
                enforcement agencies or third parties. Therefore, although we use industry-standard
                practices to protect your
                privacy, we do not promise, and you should not expect, that your personally identifiable
                information or private
                communications would always remain private. We do however assure you that any disclosure
                of your personally
                identifiable information shall be personally intimated to you through an email sent to
                your provided email address.
            </p>
            <p>As a matter of policy, we do not sell or rent any personally identifiable information
                about you to any third party.
                However, the following describes some of the ways that your personally identifiable
                information may be disclosed:
    
            </p>
            <ul>
                <li>
                    External Service Providers: There may be several services offered by external
                    service providers that help you
                    use our Platform. If you choose to use these optional services, and in the course of
                    doing so, disclose
                    information to the external service providers, and/or permit them to collect
                    information about you, then their
                    use of your information is governed by their privacy policy.
    
                </li>
                <li>Law and Order: We cooperate with law enforcement inquiries, as well as other third
                    parties to enforce laws, such
                    as intellectual property rights, fraud and other rights. We can (and you authorize
                    us to) disclose any
                    information about you to law enforcement and other government officials as we, in
                    our sole discretion, believe
                    necessary or appropriate, in connection with an investigation of fraud, intellectual
                    property infringements, or
                    other activity that is illegal or may expose us or you to legal liability.</li>
            </ul>
            <h3><strong>ACCESSING, REVIEWING AND CHANGING YOUR PROFILE</strong></h3>
            <p>
                Following registration, you can review and change the information you submitted at the
                stage of except Email ID and
                mobile number. An option for facilitating such change shall be present on the Platform
                and such change shall be
                facilitated by the User. If you change any information, we may or may not keep track of
                your old information. We
                will not retain in our files information you have requested to remove for certain
                circumstances, such as to resolve
                disputes, troubleshoot problems and enforce our terms and conditions. Such prior
                information shall be completely
                removed from our databases, including stored ‘back up’ systems. If you believe that any
                information, we are holding
                on you is incorrect or incomplete, or to remove your profile so that others cannot view
                it, the User needs to
                remediate, and promptly correct any such incorrect information.
            </p>
            <h3><strong>SECURITY</strong></h3>
            <p>
                We treat data as an asset that must be protected against loss and unauthorized access.
                We employ many different
                security techniques to protect such data from unauthorized access by members inside and
                outside the Company. We
                follow generally accepted industry standards to protect the Personal Information
                submitted to us and information
                that we have accessed.
                However, as effective as encryption technology is, no security system is impenetrable.
                Our Company cannot guarantee
                the security of our database, nor can we guarantee that information you provide won’t be
                intercepted while being
                transmitted to the Company over the Internet.
            </p>
            <h3><strong>SEVERABILITY</strong></h3>
            <p>
                Each paragraph of this Privacy Policy shall be and remain separate from and independent
                of and severable from all
                and any other paragraphs herein except where otherwise expressly indicated or indicated
                by the context of the
                agreement. The decision or declaration that one or more of the paragraphs are null and
                void shall not affect the
                remaining paragraphs of this privacy policy.
            </p>
            <h3><strong>AMENDMENT</strong></h3>
            <p>
                Our Privacy Policy may change from time to time. The most current version of the policy
                will govern our use of your
                information and will always be at the Platform. Any amendments to this Policy shall be
                deemed as accepted by the
                User on their continued use of the Platform.
            </p>
            <h3><strong>CONSENT WITHDRAWAL, DATA DOWNLOAD & DATA REMOVAL REQUESTS</strong></h3>
            <p>
                To withdraw your consent, or to request the download or delete your data with us for any
                or all our services at any
                time, please email to <strong>info@ultrasound.net.in</strong>
            </p>
            <h3><strong>CONTACT US</strong></h3>
            <p>
                If you have any questions or concerns regarding this privacy policy, you should contact
                us by sending an e-mail to
                please email to <strong>info@ultrasound.net.in</strong></p>
            <p> Information provided on the Platform may not be accurate and may be provided for
                promotional purposes of the
                business.
            </p>
        </div>
    </div>
</div>
</div>